import {
    CREATE_ADMIN,
    RETRIEVE_ADMINS,
    UPDATE_ADMIN,
    DELETE_ADMIN
} from "../actions/types";

const initialState = [];

function adminReducer(admins = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CREATE_ADMIN:
            return [...admins, payload];

        case RETRIEVE_ADMINS:
            return payload;

        case UPDATE_ADMIN:
            return admins.map((admin) => {
                if (admin.id === payload.id) {
                    return {
                        ...admin,
                        ...payload,
                    };
                } else {
                    return admin;
                }
            });

        case DELETE_ADMIN:
            return admins.filter(({ id }) => id !== payload.id);

        default:
            return admins;
    }
};

export default adminReducer;