import React, {Component} from "react";
import ArrowDownOnSquareIcon from '@heroicons/react/24/solid/ArrowDownOnSquareIcon';
import ArrowUpOnSquareIcon from '@heroicons/react/24/solid/ArrowUpOnSquareIcon';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';

import CountryService from "../../services/CountryService";
import {Scrollbar} from "../../components/scrollbar";
import {format} from "date-fns";
import {getInitials} from "../../utils/get-initials";
import CountryItem from "./CountryItem";

import {
    Button, Container, SvgIcon,
    Avatar,
    Box,
    Card,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';

class Index extends Component {
    constructor(props) {
        super(props);

        this.retreiveCountries = this.retreiveCountries.bind(this);
        this.changePage = this.changePage.bind(this);
        this.deleteAdmin = this.deleteAdmin.bind(this);
        this.refresh = this.refresh.bind(this);
        this.toggleStatus = this.toggleStatus.bind(this);

        this.state = {
            countries: [],
            loading: false,
            total: 0,
            page: 1,
            limit: 10,
            total_pages: 0,
            progress_items: [],
            selected_items: []
        }
    }

    componentDidMount() {
        this.retreiveCountries();
    }

    async retreiveCountries() {
        this.setState({
            loading: true
        })
        var res = await CountryService.getAll(this.state.page, this.state.limit);

        this.setState({
            loading: false,
            countries: res.data.data,
            total_pages: res.data.total_pages,
            total: res.data.total
        })

        console.log(res);
    }

    changePage(page) {
        this.setState({
            page: page,
            countries: []
        }, () => {
            this.retreiveCountries();
        })

    }

    async deleteAdmin(country) {
        this.setState({
            progress_items: [...this.state.progress_items, country]
        })
        var res = await CountryService.delete(country.id);
        this.refresh();
    }

    async toggleStatus(country) {
        this.setState({
            progress_items: [...this.state.progress_items, country]
        })
        var res = await CountryService.toggleStatus(country.id);
        this.setState({
            progress_items: this.state.progress_items.filter(item => item.id != country.id ),
            countries: this.state.countries.map((c) => {
                if (c.id === country.id) {
                    return {
                        ...c,
                        active: !c.active
                    };
                } else {
                    return c;
                }
            })
        })
    }

    refresh() {
        this.setState({
            page: 1,
            countries: [],
            progress_items: [],
            selected_items: []
        })
        this.retreiveCountries();
    }

    render() {

        const {countries, loading, total, limit, page, total_pages} = this.state;
        return (
            <>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        py: 5
                    }}
                >
                    <Container maxWidth="xl">
                        <Stack spacing={3}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={4}
                            >
                                <Stack spacing={1}>
                                    <Typography variant="h4">
                                        Pays
                                    </Typography>
                                </Stack>
                            </Stack>

                            <Card>
                                <Scrollbar>
                                    <Box sx={{minWidth: 800}}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>

                                                    <TableCell>
                                                        ID
                                                    </TableCell>
                                                    <TableCell>

                                                    </TableCell>
                                                    <TableCell>
                                                        Code
                                                    </TableCell>
                                                    <TableCell>
                                                        Nom Fr
                                                    </TableCell>
                                                    <TableCell>
                                                        Nom En
                                                    </TableCell>
                                                    <TableCell>
                                                        Active ?
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {countries.map((country) => (
                                                        <CountryItem country={country} key={country.id}
                                                                     deleteAdmin={this.deleteAdmin}

                                                                     in_progress={
                                                                         this.state.progress_items.findIndex(
                                                                             (item) => item.id === country.id
                                                                         ) !== -1
                                                                             ? true
                                                                             : false
                                                                     }

                                                                     selected={
                                                                         this.state.selected_items.findIndex(
                                                                             (item) => item.id === country.id
                                                                         ) !== -1
                                                                             ? true
                                                                             : false
                                                                     }

                                                                     toggleStatus={this.toggleStatus}
                                                        />
                                                    )
                                                )}


                                            </TableBody>
                                        </Table>

                                        <Box sx={{display: 'flex', justifyContent: 'center', padding: 4}}>
                                            {
                                                loading &&
                                                <CircularProgress/>
                                            }

                                        </Box>

                                    </Box>
                                </Scrollbar>
                                {/*<TablePagination*/}
                                {/*    component="div"*/}
                                {/*    count={total}*/}
                                {/*    onPageChange={(e) => {*/}
                                {/*        console.log(e);*/}
                                {/*    }}*/}
                                {/*    onRowsPerPageChange={()=> {*/}
                                {/*        console.log(e);*/}
                                {/*    }}*/}
                                {/*    page={page}*/}
                                {/*    rowsPerPage={limit}*/}
                                {/*    rowsPerPageOptions={[5, 10, 25]}*/}
                                {/*/>*/}
                                {
                                    !loading &&
                                    <Box sx={{display: 'flex', justifyContent: 'center', padding: 4, pt:0}}>
                                        <Pagination count={total_pages} page={page} onChange={(e, value) => {
                                            this.changePage(value);
                                        }}/>
                                    </Box>
                                }

                            </Card>


                        </Stack>
                    </Container>
                </Box>
            </>
        )
    }
}

export default Index;
