import React, {Component} from "react";
import {Link} from 'react-router-dom';

import {
    Avatar,
    Box,
    Card,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Chip,
    Typography
} from '@mui/material';
import {format} from "date-fns";
import {getInitials} from "../../utils/get-initials";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {styled} from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const BootstrapTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} arrow classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

class AdminItem extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {admin, deleteAdmin, in_progress, selected, toggleSelectAdmin} = this.props;
        // const isSelected = selected.includes(customer.id);
        // const createdAt = format(customer.createdAt, 'dd/MM/yyyy');
        return (
            <>

                <TableRow
                    hover
                    selected={false}
                >

                    <TableCell>
                        {admin.id}
                    </TableCell>

                    <TableCell>
                        {admin.firstname}
                    </TableCell>

                    <TableCell>
                        {admin.lastname}
                    </TableCell>

                    <TableCell>
                        {admin.email}
                    </TableCell>
                    <TableCell align={"center"}>
                        <Chip size={'small'} label={admin.roles && Array.isArray(admin.roles) ? admin.roles[0] : ""}>
                        </Chip>

                    </TableCell>
                    <TableCell>

                        {
                            in_progress && <CircularProgress/>
                        }
                        {
                            !in_progress && <>

                                <BootstrapTooltip title="Supprimer l'admin" placement="top"
                                                  color={"primary"}>
                                    <IconButton aria-label="delete" size="large" color={"error"}
                                                onClick={() => {
                                                    deleteAdmin(admin)
                                                }}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </BootstrapTooltip>

                                <BootstrapTooltip title="Modifier l'admin" placement="top"
                                                  color={"primary"}>
                                    <IconButton color="primary" aria-label="Modifier"
                                                component={Link}
                                                to={'/admins/update/' + admin.id}
                                    >
                                        <ModeEditIcon/>
                                    </IconButton>
                                </BootstrapTooltip>
                            </>

                        }

                    </TableCell>
                </TableRow>
            </>
        )
    }
}

export default AdminItem;
