import ArrowLeftIcon from '@heroicons/react/24/solid/ArrowLeftIcon';
import { Box, Button, Container, SvgIcon, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
const Loading = () => (
    <>
        <Box
            component="main"
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexGrow: 1,
                minHeight: '100%'
            }}
        >
            <Container maxWidth="md">
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Box
                        sx={{
                            mb: 3,
                            textAlign: 'center'
                        }}
                    >
                        <CircularProgress sx={{ width: "120px", height: "120px"}}/>

                    </Box>
                    <Typography
                        align="center"
                        sx={{ mb: 3 }}
                        variant="h3"
                    >
                        Chargement ...
                    </Typography>
                </Box>
            </Container>
        </Box>
    </>
);

export default Loading;