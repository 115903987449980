import React, { Component } from "react";
import { Link } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import dayjs from "dayjs";
import 'dayjs/locale/fr';
dayjs.locale('fr');
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime)

import {
    Avatar,
    Box,
    Card,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    Chip,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import { format } from "date-fns";
import { getInitials } from "../../utils/get-initials";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

class ProposalItem extends Component {
    constructor(props) {
        super(props);
        this.renderStatus = this.renderStatus.bind(this);

    }

    renderStatus(status) {
        console.log("status", status);
        let color, label;

        switch (status) {
            case 5:
            case 6:
                label = "Refusée";
                color = "error";
                break;
            case 4:
                label = "Acceptée";
                color = "success";
                break;
            case 1:
                label = "En attente";
                color = "default";
                break;

            default:
                break;
        }
        return <Chip label={label} color={color} >

        </Chip>
    }

    render() {
        const { proposal } = this.props;
        // const isSelected = selected.includes(customer.id);
        // const createdAt = format(customer.createdAt, 'dd/MM/yyyy');
        return (
            <>

                <TableRow
                    hover
                    selected={false}
                >
                    <TableCell>
                        {proposal.id}
                    </TableCell>

                    <TableCell>
                        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                            <Avatar
                                sx={{ border: "1px solid #ddd" }}
                                src={"https://bourse.seltygo.com" + proposal.offre.user.logo}
                            />
                            <div>
                                <p style={{ fontWeight: "bolder" }}>{proposal.offre.user.society}</p>
                                <p>Offre #{proposal.offre.uid}</p>
                            </div>
                        </div>
                    </TableCell>
                    <TableCell>
                        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                            <Avatar
                                sx={{ border: "1px solid #ddd", width: 32, height: 32 }}
                                src={"https://bourse.seltygo.com" + proposal.transporteurs[0]?.logo}
                            />
                            <div>
                                <p style={{ fontWeight: "bolder" }}>{proposal.transporteurs[0]?.society}</p>
                                <p>Il y a {dayjs(proposal.created_at).fromNow()}</p>
                            </div>
                        </div>
                    </TableCell>
                    <TableCell>
                        {proposal.purpose_amount ? (proposal.purpose_amount.toString() + " " + proposal.offre.currency) : "--"}
                    </TableCell>
                    <TableCell>
                        {
                            !proposal.purpose_start_at && !proposal.purpose_end_at ?
                                "--"
                                :
                                <>
                                    {
                                        proposal.purpose_start_at &&
                                        <>
                                            <p>Départ : {dayjs(proposal.purpose_start_at).format('DD/MM/YYYY')}</p>
                                        </>
                                    }
                                    {
                                        proposal.purpose_end_at &&
                                        <>
                                            <p>Arrivée : {dayjs(proposal.purpose_end_at).format('DD/MM/YYYY')}</p>
                                        </>
                                    }
                                </>
                        }

                    </TableCell>
                    <TableCell>
                        {
                            proposal.chauffeur ?
                                "Affecté"
                                :
                                "--"
                        }
                    </TableCell>
                    <TableCell>
                        {this.renderStatus(proposal.status)}
                    </TableCell>
                </TableRow>
            </>
        )
    }
}

export default ProposalItem;
