import React, { Component } from "react";
import UserDataService from "../../services/UserService";
import CircularProgress from "@mui/material/CircularProgress";
import {
    Alert,
    Box,
    Button,
    FormHelperText,
    Link,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography,
    Container,
    SvgIcon,


} from '@mui/material';
import DocumentItem from  "./DocumentItem";

import AcceptFile from "./AcceptFile";
import RefuseFile from "./RefuseFile";
import UpdateExpireAt from "./UpdateExpireAt";
import UserService from "../../services/UserService";

class Folder extends Component{
    constructor(props) {
        super(props);

        this.acceptDocument =this.acceptDocument.bind(this);
        this.refuseDocument = this.refuseDocument.bind(this);

        this.confirmAcceptDocument =this.confirmAcceptDocument.bind(this);
        this.confirmRefuseDocument = this.confirmRefuseDocument.bind(this);

        this.cancelAcceptDocument =this.cancelAcceptDocument.bind(this);
        this.cancelRefuseDocument = this.cancelRefuseDocument.bind(this);
        this.refresh = this.refresh.bind(this);

        this.updateExpireAtItem = this.updateExpireAtItem.bind(this);
        this.cancelUpdateExpireAtItem = this.cancelUpdateExpireAtItem.bind(this);
        this.confirmExpireAtItem = this.confirmExpireAtItem.bind(this);

        this.state = {
            showLoading: true,
            folder: [],
            user: null,
            info: '',
            error: '',

            acceptItem: null,
            refuseItem: null,
            expireAtItem: null,
        }
    }

    loadFolder(id) {
        this.setState({
            showLoading: true
        });
        UserDataService.getFolder(id)
            .then((response) => {
                this.setState({
                    showLoading: false,
                    folder:response.data.folder,
                    user: response.data.user
                });
            })
            .catch((e) => {
                this.setState({
                    showLoading: false
                });
                console.log(e);
            });
    }

    refresh(){
        this.loadFolder(this.props.match.params.id);
    }
    componentDidMount() {
        this.refresh();
    }

    acceptDocument(item){
        this.setState({
            acceptItem: item
        })
    }

    refuseDocument(item){
        this.setState({
            refuseItem: item
        })
    }

    confirmAcceptDocument(item){
        UserDataService.acceptDocument(item.id).then((res) => {
            this.cancelAcceptDocument();
            this.refresh();
        }).catch((err) => {
            console.log(err);
        });
    }

    confirmRefuseDocument(item){
        UserDataService.refuseDocument(item.id).then((res) => {
            this.cancelRefuseDocument();
            this.refresh();
        }).catch((err) => {
            console.log(err);
        });
    }

    cancelAcceptDocument(){
        this.setState({
            acceptItem: null
        })
    }

    cancelRefuseDocument(){
        this.setState({
            refuseItem: null
        })
    }

    updateExpireAtItem(item){
        this.setState({
            expireAtItem: item
        })
    }
    confirmExpireAtItem(item, date){
        UserService.updateDocumentExpireAt(item, date).then((res) => {
            this.cancelUpdateExpireAtItem();
            this.refresh();
        }).catch((e)=> {

        });
    }
    cancelUpdateExpireAtItem(){
        this.setState({
            expireAtItem: null
        })
    }

    render() {
        const {width} = this.props;

        const {form, loading, error, info, errors, showLoading, user, folder} = this.state;
        return (
            <>
                <Box
                    component="main"
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        marginTop: 3
                    }}
                >
                    <Container maxWidth="lg">


                        <Typography
                            align="center"
                            sx={{mb: 3}}
                            variant="h3"
                        >
                            Dossier : { user && <>{ user.firstname} {user.lastname}</>}
                        </Typography>

                        {
                            error &&
                            <Alert severity="error" variant="filled" sx={{mb: 2}}>
                                <AlertTitle>Erreur</AlertTitle>
                                {error}
                            </Alert>
                        }

                        {
                            info &&
                            <Alert severity="success" variant="filled" sx={{mb: 2, color: "#fff"}} >
                                <AlertTitle>Succes</AlertTitle>
                                {info}
                            </Alert>
                        }

                        {
                            showLoading &&
                            <Box sx={{display: 'flex', justifyContent: 'center', padding: 4}}>

                                <CircularProgress/>

                            </Box>
                        }
                        {
                            !showLoading &&
                            <>
                            {folder.map((documentType) => (
                                <DocumentItem documentType={documentType} key={documentType.id}
                                              acceptDocument={this.acceptDocument}
                                              refuseDocument={this.refuseDocument}
                                              updateExpireAtItem={this.updateExpireAtItem}
                                />
                                )
                            )}
                            </>
                        }


                    </Container>

                    {
                        this.state.acceptItem &&
                        <AcceptFile item={this.state.acceptItem} handleClose={this.cancelAcceptDocument} confirmAccept={this.confirmAcceptDocument}/>

                    }
                    {
                        this.state.refuseItem &&
                        <RefuseFile item={this.state.refuseItem} handleClose={this.cancelRefuseDocument} confirmRefuse={this.confirmRefuseDocument}/>

                    }

                    {
                        this.state.expireAtItem && <UpdateExpireAt item={this.state.expireAtItem}
                                                                   handleClose={this.cancelUpdateExpireAtItem}
                                                                   confirmExpireAt={this.confirmExpireAtItem}
                        />
                    }
                </Box>
            </>
        )
    }
}

export default Folder;