import http from "../ http-common";
import axios from "axios"
import {BASE_URL} from '../config';
class AuthService {
    login(email, password) {
        var formData = new FormData();
        let config = {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': "application/json",
                'Accept': "application/json",
                'X-Requested-With': "XMLHttpRequest",
                'Access-Control-Allow-Headers': '*',
            }
        }
        return axios.post(BASE_URL+"/admin/login_check", {
            username: email,
            password: password
        }, config);
    }

    me(){
        return http.get("/admin/me");
    }

    logout(){
        return http.get("/admin/logout");
    }
}

export default new AuthService();
