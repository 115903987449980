import React, {Component} from "react";
import {Link} from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import {format} from "date-fns";
import {getInitials} from "../../utils/get-initials";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {styled} from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const BootstrapTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} arrow classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

class CountryItem extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const {country, deleteCountry, in_progress, selected, toggleSelectCountry, toggleStatus} = this.props;
        // const isSelected = selected.includes(customer.id);
        // const createdAt = format(customer.createdAt, 'dd/MM/yyyy');
        return (
            <>

                <TableRow
                    hover
                    selected={false}
                >

                    <TableCell>
                        {country.id}
                    </TableCell>

                    <TableCell>
                        <Avatar
                            sx={{border: "1px solid #ddd"}}
                            alt=""
                            src={"https://flagcdn.com/160x120/" + country.alpha2.toLowerCase() + ".png"}

                        />
                    </TableCell>
                    <TableCell>
                        {country.code}
                    </TableCell>


                    <TableCell>
                        {country.nom_fr_fr}
                    </TableCell>

                    <TableCell>
                        {country.nom_en_gb}
                    </TableCell>

                    <TableCell>
                        {
                            !in_progress && <>

                                <FormGroup>
                                    <FormControlLabel control={<Switch checked={country.active}
                                                                       onChange={() => {
                                                                           toggleStatus(country)
                                                                       }}
                                    />} label=""/>
                                </FormGroup>
                            </>
                        }
                        {
                            in_progress && <CircularProgress/>
                        }
                    </TableCell>
                </TableRow>
            </>
        )
    }
}

export default CountryItem;
