import http from "../ http-common";
import {BASE_URL as API_URL} from '../config';

class DocumentTypeService {

    getAll(page, limit, filter) {
        return http.get("/admin/document_types", {
            params: {
                page,
                limit,
                ...filter
            }
        });
    }

    get(id) {
        return http.get(`/admin/document_types/${id}`);
    }

    create(form) {
        var formData = new FormData();
        for (const [key, value] of Object.entries(form)) {
            formData.append(`${key}`, `${value}`)
        }
        return http.post( "/admin/document_types", formData);
    }

    update(id, form) {
        // send action
        var formData = new FormData();
        for (const [key, value] of Object.entries(form)) {
            formData.append(`${key}`, `${value}`)
        }
        return http.post(`/admin/document_types/update`, formData);
    }

    delete(id) {
        return http.delete(`/admin/document_types/${id}`);
    }

    deleteAll() {
        return http.delete(`/admin/document_types`);
    }

    toggleStatus(id, attribute) {
        return http.get(`/admin/document_types/toggle_status/${id}/${attribute}`);
    }

}

export default new DocumentTypeService();
