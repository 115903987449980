import React, { Component } from "react";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import {
    Alert,
    Box,
    Button,
    FormHelperText,
    Link,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography,
    Container,
    SvgIcon

} from '@mui/material';

import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {styled} from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';


import dayjs from "dayjs";
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';

const BootstrapTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} arrow classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

class DocumentItem extends Component{
    constructor(props) {
        super(props);

        this.getFullUrl = this.getFullUrl.bind(this);
    }

    getFullUrl(path){
        console.log("ENV")
        console.log(process.env.APP_BASE_URL);
        return "" + path;
    }

    getStatusColor(status){
        switch (status) {
            case 0:
                return "warning";
            case 1:
                return "success";
            case 2:
                return "error";
        }
    }

    getStatusText(status){
        switch (status) {
            case 0:
                return "En attente";
            case 1:
                return "Valide";
            case 2:
                return "Refuse";
        }
    }

    render() {
        const {documentType, refuseDocument, acceptDocument, updateExpireAtItem} = this.props;

        const in_progress = false;

        return (
            <>
                <Accordion  expanded={true}>
                    <AccordionSummary

                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h6">{documentType.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">ID</TableCell>
                                        <TableCell align="center">Nom de fichier</TableCell>
                                        <TableCell align="center">Date d'importation</TableCell>
                                        <TableCell align="center">Expire le</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center">Examine par</TableCell>
                                        <TableCell align="center">Examine le</TableCell>
                                        <TableCell align="center">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {documentType.documents.map((document) => (
                                        <TableRow
                                            key={document.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" align="center">
                                                {document.id}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="center">
                                                {document.original_file_name}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="center">
                                                {dayjs(document.created_at).format('DD/MM/YYYY')}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="center">
                                                <Chip label={
                                                    document.expire_at ? dayjs(document.expire_at).format('DD/MM/YYYY') : "-"
                                                } variant="outlined" onClick={() => updateExpireAtItem(document)} />


                                            </TableCell>
                                            <TableCell component="th" scope="row" align="center">
                                                <Chip size="small" label={this.getStatusText(document.status)}
                                                      color={this.getStatusColor(document.status)} />
                                            </TableCell>

                                            <TableCell>
                                                {
                                                    document.admin &&  <>
                                                        {document.admin.firstname} {document.admin.lastname}

                                                    </>
                                                }

                                            </TableCell>

                                            <TableCell>
                                                {dayjs(document.updated_at).format('DD/MM/YYYY')}
                                            </TableCell>

                                            <TableCell component="th" scope="row" align="center">
                                                {
                                                    <BootstrapTooltip title="Refuser" placement="top"
                                                                      color={"primary"}>
                                                        <IconButton aria-label="delete" size="large" color={"error"}
                                                                    aria-label="Refuser"
                                                                    onClick={() => {
                                                                        refuseDocument(document)
                                                                    }}
                                                        >
                                                            <CloseIcon/>
                                                        </IconButton>
                                                    </BootstrapTooltip>
                                                }
                                                {
                                                    <BootstrapTooltip title="Valider" placement="top" color={"primary"}>
                                                        <IconButton aria-label="valider" size="large" color={"success"}
                                                                    aria-label="Valider"
                                                                    onClick={() => {
                                                                        acceptDocument(document)
                                                                    }}
                                                        >
                                                            <DownloadDoneIcon/>
                                                        </IconButton>
                                                    </BootstrapTooltip>
                                                }

                                                <BootstrapTooltip title="Consulter" placement="top" color={"primary"}>
                                                    <IconButton aria-label="consulter" size="large" color={"primary"}
                                                                aria-label="Valider"
                                                                component={'a'}
                                                                target={document.full_path}
                                                                href={ document.full_path}

                                                    >
                                                        <RemoveRedEyeIcon />
                                                    </IconButton>
                                                </BootstrapTooltip>

                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }
}

export default DocumentItem;