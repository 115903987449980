import http from "../ http-common";
import axios from "axios";
import {BASE_URL as API_URL} from '../config';
const BASE_URL = API_URL+"/admin";
class UserService {
    getAll(page, limit, filter) {
        return http.get(BASE_URL+ "/users", {
            params: {
                page,
                limit,
                ...filter
            }
        });
    }

    get(id) {
        return http.get(`/admin/users/${id}`);
    }

    create(data) {
        var formData = new FormData();
        formData.append("password", data.password);
        return axios.post(BASE_URL+ "/users", data);

        //return http.post("/admin/users", data);
    }

    update(id, form) {
        // send action
        var formData = new FormData();
        for (const [key, value] of Object.entries(form)) {

                formData.append(`${key}`, `${value}`)

        }

        let config = {
            headers: {
                'withCredentials': false,
                'Authorization': 'Bearer ' + window.localStorage.token,
                // 'Access-Control-Allow-Origin': '*',
                // 'Accept': "*/*",
                // 'X-Requested-With': "XMLHttpRequest",
                // // 'Access-Control-Allow-Headers': '*',
                // 'Content-Type': 'application/json'
            }
        }

        return axios.post(`/admin/users/update`, formData, config);
    }

    delete(id) {
        return http.delete(`/admin/users/${id}`);
    }

    deleteAll() {
        return http.delete(`/admin/users`);
    }

    toggleStatus(id, attribute){
        return http.get(`/admin/users/toggle_status/${id}/${attribute}`);
    }

    getFolder(id) {
        return http.get(`/admin/users/folder/${id}`);
    }

    acceptDocument(id) {
        return http.get(`/admin/document/accept/${id}`);
    }

    refuseDocument(id) {
        return http.get(`/admin/document/refuse/${id}`);
    }

    updateDocumentExpireAt(item, date){

        var formData = new FormData();
        formData.append('document_id', item.id);
        formData.append('date', date);
        return http.post(`/admin/document/expire_at`, formData);
    }

}

export default new UserService();
