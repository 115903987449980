import { LOGIN, LOGOUT } from "./types";
import axios from "axios";
import http from "../../ http-common"

import AuthService from "../../services/AuthService";

export const login = (email, password) => async (dispatch) => {
    try {
        const res = await AuthService.login(email, password);
        await window.localStorage.setItem("token", res.data.token);
        await window.localStorage.setItem("refresh_token", res.data.refresh_token);
        axios.defaults.headers["Authorization"] = "Bearer " + res.data.token;
        http.defaults.headers['Authorization'] = "Bearer " + res.data.token;

        dispatch({
            type: LOGIN,
            payload: res.data.user,
        });

        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const me = () => async (dispatch) => {
    try {
        const res = await AuthService.me();
        dispatch({
            type: LOGIN,
            payload: res.data.user,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const logout = () => async (dispatch) => {
    try {
        const res = await AuthService.logout();

        window.localStorage.removeItem("token");
        window.localStorage.removeItem("refresh_token");
        axios.defaults.headers["Authorization"] = "";

        dispatch({
            type: LOGOUT,
            payload: null,
        });

        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
}



