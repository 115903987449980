import axios from "axios";

import {BASE_URL} from './config';

const auth = window.localStorage.token ? 'Bearer '+window.localStorage.token : null;

export default axios.create({
    baseURL: BASE_URL,
    //withCredentials: true,
    headers: {
        'withCredentials' : true,
        'Authorization':  auth,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': "application/json",
        'Accept': "application/json",
        'X-Requested-With': "XMLHttpRequest",
        'Access-Control-Allow-Headers': '*'
    }
});
