import { useTheme } from '@mui/material/styles';
import SVGLogo from '../assets/logo.svg';
import SVGLogoWhite from '../assets/logo_white.svg';

export const Logo = ({white = false}) => {
  const theme = useTheme();
  const fillColor = theme.palette.primary.main;

  return (
    <img
      alt=""
      src={white ? SVGLogoWhite : SVGLogo}
    />
  );
};
