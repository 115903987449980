import React, { Component } from "react";

// Components
import CircularProgress from "@mui/material/CircularProgress";
import {
  Alert,
  Box,
  Stack,
  Typography,
  Container,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Paper,
} from "@mui/material";
import WebErrorItem from "./WebErrorItem";

// Services
import WebErrorsService from "../../services/WebErrorsService";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "user", headerName: "Utilisateur", width: 130 },
  { field: "message", headerName: "Erreur", width: 130 },
  { field: "userAgent", headerName: "Navigateur", width: 90 },
  { field: "ip", headerName: "IP", width: 160 },
];

class Index extends Component {
  constructor(props) {
    super(props);

    this.refresh = this.refresh.bind(this);

    this.state = {
      showLoading: true,
      webErrors: [],
      info: "",
      error: "",

      acceptItem: null,
      refuseItem: null,
    };
  }

  loadErrors() {
    this.setState({
      showLoading: true,
    });
    WebErrorsService.getErrors()
      .then((response) => {
        this.setState({
          showLoading: false,
          webErrors: response.data,
        });
      })
      .catch((e) => {
        this.setState({
          showLoading: false,
        });
        console.log(e);
      });
  }

  refresh() {
    this.loadErrors();
  }

  componentDidMount() {
    this.refresh();
  }

  render() {
    const { error, info, showLoading, webErrors } = this.state;
    return (
      <>
        <Box
          component="main"
          sx={{
            alignItems: "center",
            display: "flex",
            marginTop: 3,
          }}
        >
          <Container maxWidth="lg">
            <Stack spacing={3}>
              <Stack direction="row" justifyContent="space-between" spacing={4}>
                <Stack spacing={1}>
                  <Typography variant="h4">Erreurs web</Typography>
                </Stack>
              </Stack>

              {error && (
                <Alert severity="error" variant="filled" sx={{ mb: 2 }}>
                  <AlertTitle>Erreur</AlertTitle>
                  {error}
                </Alert>
              )}

              {info && (
                <Alert severity="success" variant="filled" sx={{ mb: 2, color: "#fff" }}>
                  <AlertTitle>Succes</AlertTitle>
                  {info}
                </Alert>
              )}

              {showLoading && (
                <Box sx={{ display: "flex", justifyContent: "center", padding: 4 }}>
                  <CircularProgress />
                </Box>
              )}

              {!showLoading && (
                <>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell align="left">Date</TableCell>
                          <TableCell align="left">Utilisateur</TableCell>
                          <TableCell align="left">Erreur</TableCell>
                          <TableCell align="left">Page</TableCell>
                          <TableCell align="left">Navigateur</TableCell>
                          <TableCell align="left"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {webErrors.map((webError) => (
                          <WebErrorItem webError={webError} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Stack>
          </Container>
        </Box>
      </>
    );
  }
}

export default Index;
