import React, { Component } from "react";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

class DeleteDialog extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const {item, handleClose, confirmDelete} = this.props;

        return (
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Voulez vous vraiment supprimer ce Type de document: <strong>{item.name}</strong> ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annuler</Button>
                    <Button onClick={() => confirmDelete(item)} autoFocus  color="error">
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default DeleteDialog;