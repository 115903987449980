import http from "../ http-common";
import axios from "axios";
import {BASE_URL as API_URL} from '../config';

const BASE_URL = API_URL+"/admin";
class CountryService {
    getAll(page, limit) {
        return http.get(BASE_URL+ "/countries", {
            params: {
                page, limit
            }
        });
    }

    get(id) {
        return http.get(`/admin/countries/${id}`);
    }

    create(data) {
        var formData = new FormData();
        formData.append("password", data.password);
        return axios.post(BASE_URL+ "/countries", data);

        //return http.post("/admin/countries", data);
    }

    update(id, form) {
        // send action
        var formData = new FormData();
        for (const [key, value] of Object.entries(form)) {

                formData.append(`${key}`, `${value}`)

        }

        let config = {
            headers: {
                'withCredentials': false,
                'Authorization': 'Bearer ' + window.localStorage.token,
                // 'Access-Control-Allow-Origin': '*',
                // 'Accept': "*/*",
                // 'X-Requested-With': "XMLHttpRequest",
                // // 'Access-Control-Allow-Headers': '*',
                // 'Content-Type': 'application/json'
            }
        }

        return axios.post(`/admin/update/admin`, form, config);
    }

    delete(id) {
        return http.delete(`/admin/countries/${id}`);
    }

    deleteAll() {
        return http.delete(`/admin/countries`);
    }


    toggleStatus(id){
        return http.get(`/admin/countries/toggle/${id}`);
    }

}

export default new CountryService();
