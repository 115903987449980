import ArrowTopRightOnSquareIcon from "@heroicons/react/24/solid/ArrowTopRightOnSquareIcon";

import { Box, Button, Divider, Drawer, Stack, SvgIcon, Typography, useMediaQuery } from "@mui/material";
import { Logo } from "../../components/logo";
import { Scrollbar } from "../../components/scrollbar";
import { items, superItems, devItems } from "./config";
import { SideNavItem } from "./side-nav-item";

export const SideNav = (props) => {
  const { open, onClose, user } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const content = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
        },
        "& .simplebar-scrollbar:before": {
          background: "neutral.400",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            height: 64,
            boxSizing: "border-box",
            px: 3,
            py: 2,
          }}
        >
          <Box
            href="/"
            sx={{
              display: "inline-flex",
              height: 32,
              width: 32,
            }}
          >
            <Logo />
          </Box>
        </Box>
        <Divider sx={{ borderColor: "#e1e1e1" }} />
        <Box
          component="nav"
          sx={{
            flexGrow: 1,
            px: 2,
            py: 3,
          }}
        >
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: "none",
              p: 0,
              m: 0,
            }}
          >
            {items.map((item) => {
              const active = false;
              const except = item.except;
              const exclude = item.exclude;

              var authorized = except ? user.roles.some((role) => except.includes(role)) : true;

              authorized = exclude ? !user.roles.some((role) => exclude.includes(role)) : authorized;

              return !authorized ? (
                <></>
              ) : (
                <SideNavItem
                  active={active}
                  disabled={item.disabled}
                  external={item.external}
                  icon={item.icon}
                  key={item.title}
                  path={item.path}
                  title={item.title}
                />
              );
            })}
          </Stack>
          <Divider
            sx={{
              borderColor: "#e1e1e1",
              fontSize: 14,
              my: 2,
            }}
            textAlign="left"
          />
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: "none",
              p: 0,
              m: 0,
            }}
          >
            {superItems.map((item) => {
              const active = false;
              const except = item.except;
              const exclude = item.exclude;

              var authorized = except ? user.roles.some((role) => except.includes(role)) : true;

              authorized = exclude ? !user.roles.some((role) => exclude.includes(role)) : authorized;

              return !authorized ? (
                <></>
              ) : (
                <SideNavItem
                  active={active}
                  disabled={item.disabled}
                  external={item.external}
                  icon={item.icon}
                  key={item.title}
                  path={item.path}
                  title={item.title}
                />
              );
            })}
          </Stack>
          <Divider
            sx={{
              borderColor: "#e1e1e1",
              fontSize: 14,
              my: 2,
            }}
            textAlign="left"
          />
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: "none",
              p: 0,
              m: 0,
            }}
          >
            {devItems.map((item) => {
              const active = false;
              const except = item.except;
              const exclude = item.exclude;

              var authorized = except ? user.roles.some((role) => except.includes(role)) : true;

              authorized = exclude ? !user.roles.some((role) => exclude.includes(role)) : authorized;

              return !authorized ? (
                <></>
              ) : (
                <SideNavItem
                  active={active}
                  disabled={item.disabled}
                  external={item.external}
                  icon={item.icon}
                  key={item.title}
                  path={item.path}
                  title={item.title}
                />
              );
            })}
          </Stack>
        </Box>
        <Divider sx={{ borderColor: "#e1e1e1" }} />
        <Box
          sx={{
            px: 2,
            py: 3,
          }}
        >
          <Button
            component="a"
            endIcon={
              <SvgIcon fontSize="small">
                <ArrowTopRightOnSquareIcon />
              </SvgIcon>
            }
            fullWidth
            href="https://bourse.seltygo.com"
            sx={{ mt: 2 }}
            target="_blank"
            variant="contained"
          >
            Ouvrir la bourse
          </Button>
        </Box>
      </Box>
    </Scrollbar>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "white",
            boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 8px",
            color: "common.white",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.800",
          color: "common.white",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};
