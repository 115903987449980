import React, { Component } from "react";
import ArrowDownOnSquareIcon from '@heroicons/react/24/solid/ArrowDownOnSquareIcon';
import ArrowUpOnSquareIcon from '@heroicons/react/24/solid/ArrowUpOnSquareIcon';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import {useSelection} from '../../hooks/use-selection';
import {Link} from 'react-router-dom';
import {UsersTable} from './users-table';
import {UsersSearch} from './users-search';
import {applyPagination} from '../../utils/apply-pagination';

import AdminDataService from "../../services/AdminService";
import {Scrollbar} from "../../components/scrollbar";
import {format} from "date-fns";
import {getInitials} from "../../utils/get-initials";
import AdminItem from "./AdminItem";

import {
    Button, Container, SvgIcon,
    Avatar,
    Box,
    Card,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';

import DeleteDialog from "./DeleteDialog";
class Index extends Component{
    constructor(props) {
        super(props);

        this.retreiveAdmins = this.retreiveAdmins.bind(this);
        this.changePage = this.changePage.bind(this);
        this.deleteAdmin = this.deleteAdmin.bind(this);
        this.refresh = this.refresh.bind(this);

        this.closeDeleteAdmin =this.closeDeleteAdmin.bind(this);
        this.confirmDeleteAdmin = this.confirmDeleteAdmin.bind(this);

        this.state = {
            admins: [],
            loading: false,
            total: 0,
            page: 1,
            limit: 10,
            total_pages: 0,
            progress_items: [],
            selected_items: [],
            deleteItem: null
        }
    }

    componentDidMount() {
        this.retreiveAdmins();
    }

    async retreiveAdmins() {
        this.setState({
            loading: true
        })
        var res = await AdminDataService.getAll(this.state.page, this.state.limit);

        this.setState({
            loading: false,
            admins: res.data.data,
            total_pages: res.data.total_pages,
            total: res.data.total
        })

        console.log(res);
    }
    changePage(page){
        this.setState({
            page: page,
            admins: []
        })
        this.retreiveAdmins();
    }

    deleteAdmin(admin){
        this.setState({
            deleteItem: admin
        })
    }
    closeDeleteAdmin(){
        this.setState({
            deleteItem: null
        })
    }
    async confirmDeleteAdmin(admin) {
        this.setState({
            progress_items: [...this.state.progress_items, admin],
            deleteItem: null
        })
        var res = await AdminDataService.delete(admin.id);
        this.refresh();
    }



    refresh(){
        this.setState({
            page: 1,
            admins: [],
            progress_items: [],
            selected_items: []
        })
        this.retreiveAdmins();
    }

    render() {

        const {admins, loading,total,limit, page,total_pages} = this.state;
        return (
            <>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        py: 5
                    }}
                >
                    <Container maxWidth="xl">
                        <Stack spacing={3}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={4}
                            >
                                <Stack spacing={1}>
                                    <Typography variant="h4">
                                        Administrateurs
                                    </Typography>
                                </Stack>
                                <div>
                                    <Button
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <PlusIcon/>
                                            </SvgIcon>
                                        )}
                                        variant="contained"
                                        component={Link}
                                        to={"/create/admin"}
                                    >
                                        Nouveau
                                    </Button>
                                </div>
                            </Stack>
                            {/* <UsersSearch/> */}


                            <Card>
                                <Scrollbar>
                                    <Box sx={{ minWidth: 800 }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        ID
                                                    </TableCell>
                                                    <TableCell>
                                                        Nom
                                                    </TableCell>
                                                    <TableCell>
                                                        Prenom
                                                    </TableCell>
                                                    <TableCell>
                                                        Email
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        Role
                                                    </TableCell>
                                                    <TableCell>

                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {admins.map((admin) => (
                                                        <AdminItem admin={admin} key={admin.id}
                                                            deleteAdmin={this.deleteAdmin}

                                                                   in_progress={
                                                                       this.state.progress_items.findIndex(
                                                                           (item) => item.id === admin.id
                                                                       ) !== -1
                                                                           ? true
                                                                           : false
                                                                   }

                                                                   selected={
                                                                       this.state.selected_items.findIndex(
                                                                           (item) => item.id === admin.id
                                                                       ) !== -1
                                                                           ? true
                                                                           : false
                                                                   }
                                                        />
                                                    )


                                                )}


                                            </TableBody>
                                        </Table>

                                        <Box sx={{ display: 'flex', justifyContent: 'center', padding: 4}}>
                                            {
                                                loading &&
                                                <CircularProgress />
                                            }

                                        </Box>

                                    </Box>
                                </Scrollbar>
                                {/*<TablePagination*/}
                                {/*    component="div"*/}
                                {/*    count={total}*/}
                                {/*    onPageChange={(e) => {*/}
                                {/*        console.log(e);*/}
                                {/*    }}*/}
                                {/*    onRowsPerPageChange={()=> {*/}
                                {/*        console.log(e);*/}
                                {/*    }}*/}
                                {/*    page={page}*/}
                                {/*    rowsPerPage={limit}*/}
                                {/*    rowsPerPageOptions={[5, 10, 25]}*/}
                                {/*/>*/}

                                <Box sx={{ display: 'flex', justifyContent: 'center', padding: 4}}>
                                    <Pagination count={total_pages} page={page} onChange={(e, value) => {
                                        this.changePage(value);
                                    }} />
                                </Box>

                            </Card>


                        </Stack>
                    </Container>
                    {
                        this.state.deleteItem &&  <DeleteDialog item={this.state.deleteItem}
                                                                handleClose={this.closeDeleteAdmin}
                                                                confirmDelete={this.confirmDeleteAdmin}/>
                    }

                </Box>
            </>
        )
    }
}

export default Index;
