
import { Box, ButtonBase } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const SideNavItem = (props) => {
  const { active = false, disabled, external, icon, path, title } = props;

  const linkProps = path
    ? external
      ? {
        component: NavLink,
        to: path,
        target: '_blank',
      }
      : {
        component: NavLink,
        to: path,
      }
    : {};

  return (
    <li>
      <ButtonBase
        sx={{
          alignItems: 'center',
          borderRadius: 1,
          color: "#989B9A",
          display: 'flex',
          justifyContent: 'flex-start',
          pl: '16px',
          pr: '16px',
          py: '6px',
          textAlign: 'left',
          width: '100%',
          ...(active && {
            backgroundColor: 'rgba(255, 255, 255, 0.04)'
          }),
          '&.active': {
            backgroundColor: '#0853A0',
            color: "white"
          },
          '&:hover': {
            backgroundColor: 'primary.dark',
            color: "white"
          }
        }}
        {...linkProps}
      >
        {icon && (
          <Box
            component="span"
            sx={{
              alignItems: 'center',
              display: 'inline-flex',
              justifyContent: 'center',
              mr: 2,
              '&.active': {
                backgroundColor: '#0853A0',
                color: "white"
              },
            }}
          >
            {icon}
          </Box>
        )}
        <Box
          component="span"
          sx={{
            flexGrow: 1,
            fontFamily: (theme) => theme.typography.fontFamily,
            fontSize: 14,
            fontWeight: 600,
            lineHeight: '24px',
            whiteSpace: 'nowrap',
            ...(active && {
              color: 'common.white'
            }),
            ...(disabled && {
              color: 'neutral.500'
            })
          }}
        >
          {title}
        </Box>
      </ButtonBase>
    </li>
  );
};

// SideNavItem.propTypes = {
//   active: PropTypes.bool,
//   disabled: PropTypes.bool,
//   external: PropTypes.bool,
//   icon: PropTypes.node,
//   path: PropTypes.string,
//   title: PropTypes.string.isRequired
// };
