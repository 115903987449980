import { Component } from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";

import { TableCell, Chip, TableRow } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const StackContainer = styled("pre")({
  background: "#f5f5f5",
  color: "#d32f2f",
  padding: "10px",
  borderRadius: "5px",
  overflowX: "auto",
  whiteSpace: "pre-wrap",
  overflow: "scroll",
  height: "300px",
  width: "100%",
});

class WebErrorItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.toggleStack = this.toggleStack.bind(this);
  }

  toggleStack() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const { id, user, message, ip, path, stack, userAgent, createdAt } = this.props.webError;

    return (
      <>
        <TableRow hover selected={false} sx={{ background: "white" }}>
          <TableCell>{id}</TableCell>

          <TableCell>{createdAt ? dayjs(user.createdAt).format("DD/MM/YYYY HH:mm") : "-"}</TableCell>

          <TableCell>
            <Chip size="small" label={user} color="primary" variant="outlined" />
          </TableCell>

          <TableCell sx={{ color: "red", fontWeight: "700" }}>{message}</TableCell>

          <TableCell>{path}</TableCell>

          <TableCell>{userAgent}</TableCell>

          <TableCell onClick={this.toggleStack} sx={{ cursor: "pointer" }}>
            {this.state.isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </TableCell>
        </TableRow>

        {this.state.isOpen ? (
          <TableRow>
            <TableCell colSpan={6}>
              <StackContainer>{stack}</StackContainer>
            </TableCell>
          </TableRow>
        ) : null}
      </>
    );
  }
}

export default WebErrorItem;
