import React, { Component } from "react";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';


class UpdateExpireDate extends Component{
    constructor(props) {
        super(props);

        this.state = {
            expire_at: null,
            value: null
        }
    }

    render() {
        const {item, handleClose, confirmExpireAt} = this.props;

        return (
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Date d'expiration
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <TextField
                            id="date"
                            label="Date d'expiration"
                            type="date"
                            defaultValue={dayjs(item.expire_at).format('YYYY-MM-DD')}
                            sx={{ width: 220, mt: 2 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => {

                                this.setState({
                                    value: e.target.value,
                                    expire_at: e.target.value.toString()
                                })
                            }}
                            value={this.state.value}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{justifyContent: 'center'}}>
                    <Button onClick={handleClose}>Annuler</Button>
                    <Button onClick={() => confirmExpireAt(item, this.state.expire_at)} autoFocus  color="error">
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default UpdateExpireDate;