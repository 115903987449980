import ArrowLeftIcon from '@heroicons/react/24/solid/ArrowLeftIcon';
import PaperAirplaneIcon from '@heroicons/react/24/solid/PaperAirplaneIcon';
import React, { Component } from "react";
import axios from "axios";
import { BASE_URL } from "../../config.js";
import {
    Alert,
    Box,
    Button,
    Stack,
    TextField,
    FormControl,
    RadioGroup,
    Radio,
    Typography,
    Container,
    SvgIcon,
    FormControlLabel,

} from '@mui/material';


import AlertTitle from '@mui/material/AlertTitle';

import { connect } from "react-redux";

import { CREATE_ADMIN } from "../../store/actions/types";
import CircularProgress from '@mui/material/CircularProgress';
import AdminDataService from "../../services/AdminService";

class Update extends Component {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
        this.changeFormAttribute = this.changeFormAttribute.bind(this);
        this.submit = this.submit.bind(this);
        this.loadAdmin = this.loadAdmin.bind(this);

        this.state = {
            loading: false,
            showLoading: false,
            form: {
                id: "",
                firstname: "",
                lastname: "",
                email: "",
                password: "",
                role: ""
            },

            errors: {
                firstname: "",
                lastname: "",
                email: "",
                password: ""
            },
            error: "",
            info: ""
        }
    }

    submit() {

        const { form } = this.state;
        this.setState({
            loading: true,
            error: "",
            info: ""
        })

        // send action
        var formData = new FormData();
        for (const [key, value] of Object.entries(form)) {
            formData.append(`${key}`, `${value}`)
        }
        let config = {
            headers: {
                'withCredentials': true,
                'Authorization': 'Bearer ' + window.localStorage.token,
                'Access-Control-Allow-Origin': '*',
                'Accept': "*/*",
            },
            validateStatus: () => true
        }
        axios.post(BASE_URL + "/admin/update/admin", formData, config).then((res) => {

            this.setState({
                loading: false,
                info: "Enregistrement  avec succes"
            })

            setTimeout(() => {
                this.props.history.push("/admins");
            }, 1000)

        }).catch((err) => {
            console.log(err)
            this.setState({
                loading: false,
                error: "Une erreur s'est produite"
            })

        });

    }

    changeFormAttribute(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }

    loadAdmin(id) {
        this.setState({
            showLoading: true
        });
        AdminDataService.get(id)
            .then((response) => {
                this.setState({
                    showLoading: false,
                    form: {
                        ...this.state.form,
                        ...response.data.admin,
                        role: response.data.admin.roles[0]
                    }
                });
            })
            .catch((e) => {
                this.setState({
                    showLoading: false
                });
                console.log(e);
            });
    }

    componentDidMount() {
        this.loadAdmin(this.props.match.params.id);
    }


    render() {

        const { form, loading, error, info, errors, showLoading } = this.state;
        return (
            <>
                <Box
                    component="main"
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                    }}
                >
                    <Container maxWidth="md">


                        <Typography
                            align="center"
                            sx={{ mb: 3, mt: 4 }}
                            variant="h3"
                        >
                            Information administrateur
                        </Typography>


                        {
                            error &&
                            <Alert severity="error" variant="filled" sx={{ mb: 2 }}>
                                <AlertTitle>Erreur</AlertTitle>
                                {error}
                            </Alert>
                        }

                        {
                            info &&
                            <Alert severity="success" variant="filled" sx={{ mb: 2, color: "#fff" }} >
                                <AlertTitle>Succes</AlertTitle>
                                {info}
                            </Alert>
                        }

                        {
                            showLoading &&
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 4 }}>

                                <CircularProgress />

                            </Box>
                        }
                        {
                            !showLoading &&
                            <form
                                noValidate
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    this.submit();
                                }}
                            >
                                <Stack spacing={3} sx={{
                                    '& .MuiInputBase-input': {
                                        background: "#fff"
                                    }
                                }}>
                                    <TextField
                                        error={errors.firstname}
                                        fullWidth
                                        helperText={errors.firstname}
                                        label="Nom"
                                        name="firstname"
                                        onBlur={() => {
                                        }}
                                        onChange={(e) => this.changeFormAttribute("firstname", e.target.value)}
                                        type="text"
                                        value={form.firstname}
                                    />

                                    <TextField
                                        error={errors.lastname}
                                        fullWidth
                                        helperText={errors.lastname}
                                        label="Prenom"
                                        name=""
                                        onBlur={() => {
                                        }}
                                        onChange={(e) => this.changeFormAttribute("lastname", e.target.value)}
                                        type="text"
                                        value={form.lastname}
                                    />

                                    <TextField
                                        error={errors.email}
                                        fullWidth
                                        helperText={errors.email}
                                        label="Email Address"
                                        name="email"
                                        onBlur={() => {
                                        }}
                                        onChange={(e) => this.changeFormAttribute("email", e.target.value)}
                                        type="email"
                                        value={form.email}
                                    />

                                    <TextField
                                        error={errors.password}
                                        variant={"filled"}
                                        fullWidth
                                        helperText={errors.password}
                                        label="Mot de passe"
                                        name="password"
                                        onBlur={() => {
                                        }}
                                        onChange={(e) => this.changeFormAttribute("password", e.target.value)}
                                        type="password"
                                        value={form.password}
                                    />

                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="role"
                                            name="role"
                                            value={form.role}
                                            onChange={(e, value) => this.changeFormAttribute("role", value)}
                                            row
                                        >
                                            <FormControlLabel value="ADMIN" control={<Radio />} label="Admin" />
                                            <FormControlLabel value="COMMERCIAL" control={<Radio />} label="Commercial" />
                                            <FormControlLabel value="SUPER_ADMIN" control={<Radio />} label="Super Admin" />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <PaperAirplaneIcon />
                                            </SvgIcon>
                                        )}
                                        sx={{ mt: 3 }}
                                        variant="contained"
                                        onClick={() => this.submit()}
                                    >
                                        Sauvegarder
                                    </Button>
                                </Box>

                            </form>
                        }


                    </Container>
                </Box>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        session: state.session.session
    };
};
export default connect(mapStateToProps, {})(Update);