import http from "../ http-common";
import axios from "axios";
import {BASE_URL as API_URL} from '../config';
const BASE_URL = API_URL+"/admin";

class ProposalsService {

    getAll(page, limit, filter) {
        return http.get(BASE_URL+ "/proposals", {
            params: {
                page,
                limit,
                ...filter
            }
        });
    }

}

export default new ProposalsService();
