import React, { Component } from "react";
import { Link } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import dayjs from "dayjs";
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    Chip,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import { format } from "date-fns";
import { getInitials } from "../../utils/get-initials";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

class UserItem extends Component {
    constructor(props) {
        super(props);
        this.isUserAdmin = this.props.isUserAdmin;
        this.renderType = this.renderType.bind(this);

    }

    renderType(user) {

        let role = user.roles.length > 0 ? user.roles[0].replace('ROLE_', '') : null;
        let roleName = "-";
        if (role) {
            var names = {
                COMMISSIONNAIRE: 'Expéditeur',
                TRANSPORTEUR: 'Transporteur',
                CHAUFFEUR: 'Chauffeur'
            };
            roleName = names[role];
        }
        return <Chip size={'small'} label={roleName} color={'success'}>

        </Chip>
    }

    render() {
        const { user, deleteUser, updateUser, in_progress, selected, toggleSelectUser, toggleStatus } = this.props;
        // const isSelected = selected.includes(customer.id);
        // const createdAt = format(customer.createdAt, 'dd/MM/yyyy');
        return (
            <>

                <TableRow
                    hover
                    selected={false}
                >

                    <TableCell>
                        {user.id}
                    </TableCell>
                    <TableCell>
                        <Chip size={'small'} color={'primary'} label={user.created_at ? dayjs(user.created_at).format('DD/MM/YYYY HH:mm') : "-"}>

                        </Chip>


                    </TableCell>

                    <TableCell>
                        {user.firstname}
                    </TableCell>

                    <TableCell>
                        {user.lastname}
                    </TableCell>

                    <TableCell>
                        {user.email}
                    </TableCell>
                    <TableCell>
                        <Chip size={'small'} label={user.telephone} icon={<LocalPhoneIcon />} color={'secondary'} />
                    </TableCell>
                    <TableCell>
                        {this.renderType(user)}
                    </TableCell>
                    <TableCell>
                        {user.society}
                    </TableCell>

                    <TableCell sx={{ display: 'flex' }}>
                        {/*{*/}
                        {/*    !in_progress && <>*/}

                        {/*        <FormGroup>*/}
                        {/*            <FormControlLabel control={<Switch checked={user.isVerified}*/}
                        {/*                                               onChange={() => {*/}
                        {/*                                                   toggleStatus(user, "isVerified")*/}
                        {/*                                               }}*/}
                        {/*            />} label=""/>*/}
                        {/*        </FormGroup>*/}
                        {/*    </>*/}
                        {/*}*/}
                        {/*{*/}
                        {/*    in_progress && <CircularProgress/>*/}
                        {/*}*/}

                        {
                            user.isVerified ?
                                <>
                                    <FileDownloadDoneIcon color={'success'} /> Oui
                                </>
                                : <>
                                    <HourglassBottomIcon color={"primary"} /> Non
                                </>
                        }
                    </TableCell>
                    {
                        this.isUserAdmin &&
                        <>
                            <TableCell>
                                {
                                    !in_progress && <>

                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={user.doc_authorizated}
                                                onChange={() => {
                                                    toggleStatus(user, "doc_authorizated")
                                                }}
                                            />} label="" />
                                        </FormGroup>
                                    </>
                                }
                                {
                                    in_progress && <CircularProgress />
                                }
                            </TableCell>

                            <TableCell>
                                {
                                    !in_progress && <>

                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={user.is_vip}
                                                onChange={() => {
                                                    toggleStatus(user, "is_vip")
                                                }}
                                            />} label="" />
                                        </FormGroup>
                                    </>
                                }
                                {
                                    in_progress && <CircularProgress />
                                }
                            </TableCell>

                            <TableCell sx={{ display: 'flex' }}>

                                {
                                    in_progress && <CircularProgress />
                                }
                                {
                                    !in_progress && <>

                                        <BootstrapTooltip title="Dossier d'utilisateur" placement="top"
                                            color={"primary"}>
                                            <IconButton size="large" color={"primary"}
                                                aria-label="Dossier"
                                                component={Link}
                                                to={"/users/folder/" + user.id}
                                            >
                                                <FolderCopyIcon />
                                            </IconButton>
                                        </BootstrapTooltip>

                                        <BootstrapTooltip title="Supprimer l'utilisateur" placement="top"
                                            color={"primary"}>
                                            <IconButton aria-label="delete" size="large" color={"error"}
                                                onClick={() => {
                                                    deleteUser(user)
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </BootstrapTooltip>

                                        <BootstrapTooltip title="Modifier l'utilisateur" placement="top"
                                            color={"primary"}>
                                            <IconButton color="primary" aria-label="Modifier"
                                                component={Link}
                                                onClick={() => {
                                                    updateUser(user)
                                                }}
                                            >
                                                <ModeEditIcon />
                                            </IconButton>
                                        </BootstrapTooltip>
                                    </>
                                }
                            </TableCell>
                        </>
                    }
                </TableRow>
            </>
        )
    }
}

export default UserItem;
