import React, { Component } from "react";

import {
    Alert,
    Box,
    Button,
    FormHelperText,
    Link,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography,
    Container, SvgIcon

} from '@mui/material';

import { Layout } from "../../layouts/dashboard/Layout"
class Home extends Component {
    render() {
        return (
            <>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        py: 5
                    }}
                >
                    <Container maxWidth="lg">
                        <Stack spacing={3}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={4}
                            >
                                <Stack spacing={1}>
                                    <Typography variant="h4">
                                        Tableau de bord
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Container>
                </Box>
            </>
        )
    }
}

export default Home;