
import { Box, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { Logo } from '../../components/logo';

// TODO: Change subtitle text

export const Layout = (props) => {
  const { children } = props;

  return (
    <Box
      component="main"
    >
      <Grid
        container
        sx={{ flex: '1 1 auto' }}
      >

          <Box
            component="header"
            sx={{
              backgroundColor: 'primary.main',
              height: 160,
              top: 0,
              width: '100%',
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "80px",
              marginBottom: "-80px",
            }}
          >
            <Box
              href="/"
              sx={{
                display: 'inline-flex',
                height: 40,
              }}
            >
              <Logo white={true} />
            </Box>
          </Box>
          {children}
      </Grid>
    </Box>
  );
};
