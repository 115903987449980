import { SvgIcon } from "@mui/material";

// Icons
import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import ChatBubbleLeftEllipsis from "@heroicons/react/24/solid/ChatBubbleLeftEllipsisIcon";
import FolderIcon from "@heroicons/react/24/solid/FolderIcon";
import GlobeAltIcon from "@heroicons/react/24/solid/GlobeAltIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import DocumentTextIcon from "@heroicons/react/24/solid/DocumentTextIcon";
import ExclamationCircleIcon from "@heroicons/react/24/solid/ExclamationCircleIcon";

export const items = [
  {
    title: "Tableau de bord",
    path: "/home",
    icon: (
      <SvgIcon fontSize="small">
        <ChartBarIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Propositions",
    path: "/proposals",
    exclude: ["COMMERCIAL"],
    icon: (
      <SvgIcon fontSize="small">
        <ChatBubbleLeftEllipsis />
      </SvgIcon>
    ),
  },
  {
    title: "Utilisateurs",
    path: "/users",
    exclude: ["COMMERCIAL"],
    icon: (
      <SvgIcon fontSize="small">
        <UsersIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Offre de service",
    path: "/offreservice",
    icon: (
      <SvgIcon fontSize="small">
        <DocumentTextIcon />
      </SvgIcon>
    ),
  },
];

export const superItems = [
  {
    title: "Administrateurs",
    path: "/admins",
    except: ["SUPER_ADMIN"],
    icon: (
      <SvgIcon fontSize="small">
        <UsersIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Type documents",
    path: "/document_types",
    except: ["SUPER_ADMIN"],
    icon: (
      <SvgIcon fontSize="small">
        <FolderIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Pays",
    path: "/countries",
    except: ["SUPER_ADMIN"],
    icon: (
      <SvgIcon fontSize="small">
        <GlobeAltIcon />
      </SvgIcon>
    ),
  },
];

export const devItems = [
  {
    title: "Erreurs web",
    path: "/webErrors",
    except: ["SUPER_ADMIN"],
    icon: (
      <SvgIcon fontSize="small">
        <ExclamationCircleIcon />
      </SvgIcon>
    ),
  },
];
