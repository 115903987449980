import http from "../ http-common";
import { BASE_URL as API_URL } from "../config";

const BASE_URL = API_URL + "/admin";

class WebErrorsService {
  getErrors(page, limit) {
    return http.get(BASE_URL + "/userErrors", {
      params: {
        page,
        limit,
      },
    });
  }
}

export default new WebErrorsService();
