import React, { useState } from 'react';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';

import {
  Button, Container, SvgIcon,
  Box,
  Card,
  Stack,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
  SVGIcon,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  AlertTitle
} from '@mui/material';
import { Scrollbar } from "../../components/scrollbar";

import ArrowDownTrayIcon from '@heroicons/react/24/solid/ArrowDownTrayIcon';
import ArrowUpOnSquareIcon from '@heroicons/react/24/solid/ArrowUpOnSquareIcon';

const trPdf = require("../../assets/pdf/tr.pdf");
const exPdf = require("../../assets/pdf/ex.pdf");

const OffreDeService = () => {
  // State for storing file inputs if needed
  const [typeInput, setTypeInput] = useState('tr');
  const [logoInput, setLogoInput] = useState(null);
  const [bgInput, setBgInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const generatePDF = async () => {
    setError(null);
    await setLoading(true);
    if (!logoInput) {
      setError('Veuillez sélectionner un fichier de logo.');
      await setLoading(false);
      return;
    }

    try {
      var pdfUrl = trPdf;
      if (typeInput === "ex") { pdfUrl = exPdf; }

      const pdfDoc = await PDFDocument.load(await fetch(pdfUrl).then(res => res.arrayBuffer()));
      const base64 = await convertBase64(logoInput);
      var logoImage = '';

      if (logoInput.type === "image/png") {
        logoImage = await pdfDoc.embedPng(base64);
      } else if (logoInput.type === "image/jpeg") {
        logoImage = await pdfDoc.embedJpg(base64);
      } else {
        setError('Le logo doit être en format PNG ou JPG.');
        return;
      }

      const pages = pdfDoc.getPages();

      pages.forEach(page => {
        const { width, height } = page.getSize();

        // Définir la position du logo en haut à gauche
        const logoWidth = 70;
        const logoHeight = (logoWidth * logoImage.height) / logoImage.width;
        const logoX = 10;
        const logoY = height - logoHeight - 10;

        if (bgInput) {
          page.drawRectangle({
            x: logoX,
            y: logoY,
            width: logoWidth,
            height: logoHeight,
            color: rgb(1, 1, 1)
          });
        }

        page.drawImage(logoImage, {
          x: logoX,
          y: logoY,
          width: logoWidth,
          height: logoHeight,
        });
      });

      const modifiedPdfBytes = await pdfDoc.save();
      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'SELTYGO - Offre de service.pdf';
      link.click();
    } catch (error) {
      console.error('Erreur lors de la manipulation du PDF :', error);
    }
    await setLoading(false);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 5
      }}
    >
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={4}
          >
            <Stack spacing={1}>
              <Typography variant="h4">
                Offre de service
              </Typography>
            </Stack>
          </Stack>


          <Card sx={{ p: 4 }}>
            <Scrollbar>

              <Box sx={{ maxWidth: "600px" }} spacing={4}>

                {error &&
                  <Alert severity="error" variant="filled" sx={{ mb: 3 }}>
                    <AlertTitle>Erreur</AlertTitle>
                    {error}
                  </Alert>
                }
                <InputLabel sx={{ marginBottom: 1 }}>Logo du client</InputLabel>
                <FormControl fullWidth sx={{ marginBottom: 3 }}>
                  <Stack spacing={6} sx={{ '& .MuiInputBase-input': { background: "#fff" } }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      component="label"
                      startIcon={(
                        <SvgIcon fontSize="small">
                          <ArrowUpOnSquareIcon />
                        </SvgIcon>
                      )}>
                      Importer
                      <input
                        type="file"
                        hidden
                        onChange={(e) => {
                          setLogoInput(e.target.files[0]);
                          setError(null);
                        }}
                      />
                    </Button>
                  </Stack>
                </FormControl>

                <InputLabel sx={{ marginBottom: 1 }}>Type du client</InputLabel>
                <FormControl fullWidth sx={{ marginBottom: 3 }}>
                  <Select
                    value={typeInput}
                    onChange={(e) => setTypeInput(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value={"tr"}>Transporteur</MenuItem>
                    <MenuItem value={"ex"}>Expéditeur</MenuItem>
                  </Select>
                </FormControl>

                <FormGroup sx={{ marginBottom: 5 }}>
                  <FormControlLabel control={<Switch checked={bgInput}
                    onChange={(e) => setBgInput(e.target.checked)}
                  />} label="Ajouter un fond blanc derrière le logo" />
                </FormGroup>

                <Button
                  disabled={loading}
                  variant="contained"
                  component="label"
                  onClick={generatePDF}
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <ArrowDownTrayIcon />
                    </SvgIcon>
                  )}
                >
                  Générer
                </Button>
              </Box>
            </Scrollbar>
          </Card>


        </Stack>
      </Container>
    </Box>

  );
};

export default OffreDeService;