import {
    LOGIN, LOGOUT
} from "../actions/types";

const initialState = {
    user: null,
    logged: false
}

function sessionReducer(session = initialState, action) {
    const {type, payload} = action;
    switch (type) {

        case LOGIN:
            return {
                user: payload,
                logged: true
            };

        case LOGOUT:
            return {
                user: null,
                logged: false
            };


        default:
            return session;
    }
};

export default sessionReducer;