import React, {Component} from "react";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import PaperAirplaneIcon from '@heroicons/react/24/solid/PaperAirplaneIcon';
import XMarkIcon from '@heroicons/react/24/solid/XMarkIcon';
import DocumentTypeService from "../../services/DocumentTypeService";
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import {
    Alert

} from '@mui/material';
import AlertTitle from '@mui/material/AlertTitle';
import axios from "axios";

import {
    Container, SvgIcon,
    Avatar,
    Box,
    Card,
    Checkbox,
    Stack,
    Typography,
    TextField
} from '@mui/material';
class FormDialog extends Component {
    constructor(props) {
        super(props);

        this.save = this.save.bind(this);

        this.state = {
            loading: false,

            form: {
                id: null,
                name: "",
                tooltip: "",
                forTransporteur: false,
                forExpediteur: false,
                isRequired: false
            },

            errors: {
            },
            error: "",
            info: ""
        }
    }
    changeFormAttribute(name, value){
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }

    save(){
        this.setState({
            loading: true,
            info: "",
            error: ""
        })
        let item = this.props.item;
        if(item){

            // update item
            DocumentTypeService.update(item.id, this.state.form).then((res) => {
                this.setState({
                    loading: false,
                    info: "Enregistrement avec succes"
                }, () => {
                    setTimeout(()=> {
                        this.props.refresh();
                        this.props.handleClose();

                    }, 1000)
                })
            }).catch((e) => {
                this.setState({
                    loading: false,
                    error: "Une erreur s'est produite"
                })
            });
        }else{
            // create new
            DocumentTypeService.create(this.state.form).then((res) => {
                this.setState({
                    loading: false,
                    info: "Enregistrement avec succes"
                }, () => {
                    setTimeout(()=> {
                        this.props.refresh();
                        this.props.handleClose();

                    }, 1000)
                })
            }).catch((e) => {
                this.setState({
                    loading: false,
                    error: "Une erreur s'est produite"
                })
            });
        }


    }
    componentDidMount() {
        const {item} = this.props;
        if(item){
            this.setState({
                form: {
                    ...this.state.form,
                    id: item.id,
                    name: item.name,
                    tooltip: item.tooltip,
                    forTransporteur: item.forTransporteur,
                    forExpediteur: item.forExpediteur,
                    isRequired: item.isRequired
                }
            })
        }

    }

    render() {
        const {item, handleClose} = this.props;
        const {errors, form, error, info, loading} = this.state;
        return (
            <>
                    <Dialog
                        open={true}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth={"md"}
                        maxWidth={"md"}
                    >
                        <DialogTitle id="alert-dialog-title" sx={{alinItems: "center"}}>
                            { item ? "Modification d'un type document" : "Nouveau type de documents"}
                        </DialogTitle>
                        <DialogContent sx={{justifyContent: "center"}}>
                            <DialogContentText id="alert-dialog-description">
                                {
                                    error &&
                                    <Alert severity="error" variant="filled" sx={{ mb: 2}}>
                                        <AlertTitle>Erreur</AlertTitle>
                                        {error}
                                    </Alert>
                                }

                                {
                                    info &&
                                    <Alert severity="success" variant="filled" sx={{ mb: 2, color: "#fff"}}>
                                        <AlertTitle>Succes</AlertTitle>
                                        {info}
                                    </Alert>
                                }

                                <form
                                    noValidate
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        this.submit();
                                    }}
                                >
                                    <Stack spacing={3} sx={{
                                        '& .MuiInputBase-input': {
                                            background: "#f1f1f1"
                                        },
                                        mt: 3
                                    }}>
                                        <TextField
                                            error={errors.name}
                                            fullWidth
                                            helperText={errors.name}
                                            label="Nom"
                                            name="name"
                                            onBlur={() => {}}
                                            onChange={(e) => this.changeFormAttribute("name", e.target.value)}
                                            type="text"
                                            value={form.name}
                                        />

                                        <TextField
                                            error={errors.tooltip}
                                            fullWidth
                                            helperText={errors.tooltip}
                                            label="Tooltip"
                                            name="tooltip"
                                            onBlur={() => {}}
                                            onChange={(e) => this.changeFormAttribute("tooltip", e.target.value)}
                                            type="text"
                                            value={form.tooltip}
                                        />

                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={form.forExpediteur}
                                                                               onChange={(e, value) => this.changeFormAttribute("forExpediteur", value)}
                                            />} label="Pour expéditeur"/>
                                        </FormGroup>

                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={form.forTransporteur}
                                                                               onChange={(e, value) => this.changeFormAttribute("forTransporteur", value)}
                                            />} label="Pour transporteur"/>
                                        </FormGroup>

                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={form.isRequired}
                                                                               onChange={(e, value) => this.changeFormAttribute("isRequired", value)}
                                            />} label="Obligatoire ?"/>
                                        </FormGroup>


                                    </Stack>
                                    <Box sx={{display: "flex", justifyContent: "center", mt: 4, mb: 1}}>

                                        <Button
                                            startIcon={(
                                                <SvgIcon fontSize="small">
                                                    <XMarkIcon  />
                                                </SvgIcon>
                                            )}
                                            sx={{ mt: 3, mr: 3 }}
                                            variant="contained"
                                            onClick={handleClose}

                                        >
                                            Annuler
                                        </Button>
                                        <Button
                                            disabled={loading}
                                                startIcon={(
                                                    <SvgIcon fontSize="small">
                                                        <PaperAirplaneIcon />
                                                    </SvgIcon>
                                                )}
                                                sx={{ mt: 3 }}
                                                variant="contained"
                                                onClick={() => this.save()}
                                            >
                                                Sauvegarder
                                            </Button>



                                    </Box>

                                </form>

                            </DialogContentText>
                        </DialogContent>
                    </Dialog>

            </>

        )
    }
}

export default FormDialog;