import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import {
    Alert,
    Box,
    Button,
    FormHelperText,
    Link,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography,

} from '@mui/material';


import AlertTitle from '@mui/material/AlertTitle';

import { connect } from "react-redux";

import { Layout } from "../../layouts/auth/layout";
import { login, me } from "../../store/actions/auth";

class Login extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.changeFormAttribute = this.changeFormAttribute.bind(this);

        this.state = {
            loading: false,

            form: {
                username: '',
                password: ''
            },
            errors: {},
            error: ""
        }
    }



    async submit() {
        const { form } = this.state;
        this.setState({
            loading: true,
            error: ""
        })
        try {
            const response = await this.props.login(form.username, form.password);
            // window.location.href = '/home';
            this.props.history.push('/home');
        } catch(err) {
            console.log(err);
            this.setState({
                error: "Email ou mot de passe incorrect"
            })
        } finally {
            this.setState({
                loading: false,
            })
        }
    }

    changeFormAttribute(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }


    render() {

        const { form, loading, error } = this.state;
        return (
            <>
                <>
                    <Layout>
                        <Box
                            sx={{
                                flex: '1 1 auto',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: "white",
                                    zIndex: 1,
                                    borderRadius: 1,
                                    maxWidth: 550,
                                    px: 3,
                                    width: '100%',
                                    padding: '24px 24px',
                                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                                }}
                            >
                                <div>



                                    <Stack
                                        spacing={1}
                                        sx={{ mb: 3 }}
                                    >
                                        <Typography variant="h4">
                                            Authentification
                                        </Typography>

                                    </Stack>

                                    {
                                        error &&
                                        <Alert severity="error" variant="filled" sx={{ mb: 2 }}>
                                            <AlertTitle>Erreur</AlertTitle>
                                            {error}
                                        </Alert>
                                    }

                                    <form
                                        noValidate
                                        onSubmit={() => {
                                        }}
                                    >
                                        <Stack spacing={3}>
                                            <TextField
                                                error={false}
                                                fullWidth
                                                helperText={""}
                                                label="Email Address"
                                                name="email"
                                                onBlur={() => { }}
                                                onChange={(e) => this.changeFormAttribute("username", e.target.value)}
                                                type="email"
                                                value={form.username}
                                            />
                                            <TextField
                                                error={false}
                                                fullWidth
                                                helperText={() => {
                                                }}
                                                label="Password"
                                                name="password"
                                                onBlur={() => { }}
                                                onChange={(e) => this.changeFormAttribute("password", e.target.value)}
                                                type="password"
                                                value={form.password}
                                            />
                                        </Stack>

                                        <Button
                                            fullWidth
                                            size="large"
                                            sx={{ mt: 3 }}
                                            type="submit"
                                            variant="contained"
                                            disabled={loading}

                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.submit()
                                            }}
                                        >
                                            Se connecter
                                        </Button>

                                    </form>


                                </div>
                            </Box>
                        </Box>
                    </Layout>
                </>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        session: state.session.session
    };
};
// export default connect(mapStateToProps, {
//     login
// })(Login);

export default withRouter(connect(mapStateToProps, {
    login
})(Login));