import React, {Component} from "react";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import PaperAirplaneIcon from '@heroicons/react/24/solid/PaperAirplaneIcon';
import XMarkIcon from '@heroicons/react/24/solid/XMarkIcon';
import UserService from "../../services/UserService";
import CircularProgress from '@mui/material/CircularProgress';

import {
    Alert

} from '@mui/material';
import AlertTitle from '@mui/material/AlertTitle';
import axios from "axios";

import {
    Container, SvgIcon,
    Avatar,
    Box,
    Card,
    Checkbox,
    Stack,
    Typography,
    TextField
} from '@mui/material';
class FormDialog extends Component {
    constructor(props) {
        super(props);

        this.save = this.save.bind(this);

        this.state = {
            loading: false,

            form: {
                firstname: "",
                lastname: "",
                email: "",
                password: ""
            },

            errors: {
                firstname: "",
                lastname: "",
                email: "",
                password: ""
            },
            error: "",
            info: ""
        }
    }
    changeFormAttribute(name, value){
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }

    save(){
        this.setState({
            loading: true,
            info: "",
            error: ""
        })
        UserService.update(this.props.item.id, this.state.form).then((res) => {
            this.setState({
                loading: false,
                info: "Enregistrement avec succes"
            }, () => {
                setTimeout(()=> {
                    this.props.refresh();
                    this.props.handleClose();

                }, 1000)
            })
        }).catch((e) => {
            this.setState({
                loading: false,
                error: "Une erreur s'est produite"
            })
        });

    }
    componentDidMount() {
        const {item, handleClose} = this.props;
        if(item){
            this.setState({
                form: {
                    ...this.state.form,
                    id: item.id,
                    firstname: item.firstname,
                    lastname: item.lastname,
                    email: item.email,
                    society: item.society,
                    password: ""
                }
            })
        }

    }

    render() {
        const {item, handleClose} = this.props;
        const {errors, form, error, info, loading} = this.state;
        return (
            <>
                {
                    item &&
                    <Dialog
                        open={true}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth={"md"}
                        maxWidth={"md"}
                    >
                        <DialogTitle id="alert-dialog-title" sx={{alinItems: "center"}}>
                            Mise a jour d'un membre
                        </DialogTitle>
                        <DialogContent sx={{justifyContent: "center"}}>
                            <DialogContentText id="alert-dialog-description">
                                {
                                    error &&
                                    <Alert severity="error" variant="filled" sx={{ mb: 2}}>
                                        <AlertTitle>Erreur</AlertTitle>
                                        {error}
                                    </Alert>
                                }

                                {
                                    info &&
                                    <Alert severity="success" variant="filled" sx={{ mb: 2, color: "#fff"}}>
                                        <AlertTitle>Succes</AlertTitle>
                                        {info}
                                    </Alert>
                                }

                                <form
                                    noValidate
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        this.submit();
                                    }}
                                >
                                    <Stack spacing={3} sx={{
                                        '& .MuiInputBase-input': {
                                            background: "#f1f1f1"
                                        },
                                        mt: 3
                                    }}>
                                        <TextField
                                            error={errors.firstname}
                                            fullWidth
                                            helperText={errors.firstname}
                                            label="Nom"
                                            name="firstname"
                                            onBlur={() => {}}
                                            onChange={(e) => this.changeFormAttribute("firstname", e.target.value)}
                                            type="text"
                                            value={form.firstname}
                                        />

                                        <TextField
                                            error={errors.lastname}
                                            fullWidth
                                            helperText={errors.lastname}
                                            label="Prenom"
                                            name=""
                                            onBlur={() => {}}
                                            onChange={(e) => this.changeFormAttribute("lastname", e.target.value)}
                                            type="text"
                                            value={form.lastname}
                                        />

                                        <TextField
                                            error={errors.email}
                                            fullWidth
                                            helperText={errors.email}
                                            label="Email Address"
                                            name="email"
                                            onBlur={() => {}}
                                            onChange={(e) => this.changeFormAttribute("email", e.target.value)}
                                            type="email"
                                            value={form.email}
                                        />

                                        <TextField
                                            error={errors.society}
                                            fullWidth
                                            helperText={errors.society}
                                            label="Société"
                                            name="email"
                                            onBlur={() => {}}
                                            onChange={(e) => this.changeFormAttribute("society", e.target.value)}
                                            type="text"
                                            value={form.society}
                                        />

                                        <TextField
                                            error={errors.password}
                                            variant={"filled"}
                                            fullWidth
                                            helperText={errors.password}
                                            label="Mot de passe"
                                            name="password"
                                            onBlur={() => {}}
                                            onChange={(e) => this.changeFormAttribute("password", e.target.value)}
                                            type="password"
                                            value={form.password}
                                        />
                                    </Stack>
                                    <Box sx={{display: "flex", justifyContent: "center", mt: 4, mb: 1}}>

                                        <Button
                                            startIcon={(
                                                <SvgIcon fontSize="small">
                                                    <XMarkIcon  />
                                                </SvgIcon>
                                            )}
                                            sx={{ mt: 3, mr: 3 }}
                                            variant="contained"
                                            onClick={handleClose}
                                        >
                                            Annuler
                                        </Button>
                                        <Button
                                            disabled={loading}
                                                startIcon={(
                                                    <SvgIcon fontSize="small">
                                                        <PaperAirplaneIcon />
                                                    </SvgIcon>
                                                )}
                                                sx={{ mt: 3 }}
                                                variant="contained"
                                                onClick={() => this.save()}
                                            >
                                                Sauvegarder
                                            </Button>



                                    </Box>

                                </form>

                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                }
            </>

        )
    }
}

export default FormDialog;