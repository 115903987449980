import React, {Component} from "react";
import ArrowDownOnSquareIcon from '@heroicons/react/24/solid/ArrowDownOnSquareIcon';
import ArrowUpOnSquareIcon from '@heroicons/react/24/solid/ArrowUpOnSquareIcon';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';

import DocumentTypeService from "../../services/DocumentTypeService";
import {Scrollbar} from "../../components/scrollbar";
import {format} from "date-fns";
import {getInitials} from "../../utils/get-initials";
import DocumentTypeItem from "./DocumentTypeItem";

import DeleteDialog from "./DeleteDialog";
import FormDialog from "./FormDialog";

import {
    Button, Container, SvgIcon,
    Avatar,
    Box,
    Card,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import UserService from "../../services/UserService";

class Index extends Component {
    constructor(props) {
        super(props);

        this.retreiveTypes = this.retreiveTypes.bind(this);
        this.changePage = this.changePage.bind(this);
        this.deleteType = this.deleteType.bind(this);
        this.refresh = this.refresh.bind(this);
        this.toggleStatus = this.toggleStatus.bind(this);

        this.deleteType = this.deleteType.bind(this);
        this.cancelDeleteType = this.cancelDeleteType.bind(this);
        this.confirmDeleteType = this.confirmDeleteType.bind(this);

        this.updateType = this.updateType.bind(this);
        this.addType = this.addType.bind(this);

        this.closeForm = this.closeForm.bind(this);

        this.state = {
            document_types: [],
            loading: false,
            total: 0,
            page: 1,
            limit: 10,
            total_pages: 0,
            progress_items: [],
            selected_items: [],

            deleteItem: null,
            updateItem: null,

            showForm: false
        }
    }

    componentDidMount() {
        this.retreiveTypes();
    }

    async retreiveTypes() {
        this.setState({
            loading: true
        })
        var res = await DocumentTypeService.getAll(this.state.page, this.state.limit);

        this.setState({
            loading: false,
            document_types: res.data.data,
            total_pages: res.data.total_pages,
            total: res.data.total
        })

        console.log(res);
    }

    changePage(page) {
        this.setState({
            page: page,
            document_types: []
        }, () => {
            this.retreiveTypes();
        })

    }

    closeForm(){
        this.setState({
            updateItem: null,
            showForm: false
        })
    }

    async deleteType(country) {
        this.setState({
            progress_items: [...this.state.progress_items, country]
        })
        var res = await DocumentTypeService.delete(country.id);
        this.refresh();
    }

    async toggleStatus(type, attribute) {
        this.setState({
            progress_items: [...this.state.progress_items, type]
        })
        var res = await DocumentTypeService.toggleStatus(type.id, attribute);
        this.setState({
            progress_items: this.state.progress_items.filter(item => item.id != type.id),
            document_types: this.state.document_types.map((dt) => {
                if (dt.id === type.id) {
                    return {
                        ...dt,
                        [attribute]: !dt[attribute]
                    };
                } else {
                    return dt;
                }
            })
        })
    }

    refresh() {
        this.setState({
            page: 1,
            document_types: [],
            progress_items: [],
            selected_items: [],
            deleteItem: null,
            updateItem: null,
            showForm: false
        })
        this.retreiveTypes();
    }

    deleteType(type) {
        this.setState({
            deleteItem: type
        });
    }

    updateType(type) {
        this.setState({
            updateItem: type,
            showForm: true
        });
    }

    addType(){
        this.setState({
            updateItem: null,
            showForm: true
        });
    }


    cancelDeleteType() {
        this.setState({
            deleteItem: null
        });
    }

    async confirmDeleteType(type) {
        this.setState({
            progress_items: [...this.state.progress_items, type]
        })
        var res = await DocumentTypeService.delete(type.id);
        this.refresh();

    }

    render() {

        const {document_types, loading, total, limit, page, total_pages} = this.state;
        return (
            <>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        py: 5
                    }}
                >
                    <Container maxWidth="xl">
                        <Stack spacing={3}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={4}
                            >
                                <Stack spacing={1}>
                                    <Typography variant="h4">
                                        Type document
                                    </Typography>
                                </Stack>
                                <div>
                                    <Button
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <PlusIcon/>
                                            </SvgIcon>
                                        )}
                                        variant="contained"
                                        onClick={(e) => this.addType()}
                                    >
                                        Nouveau
                                    </Button>
                                </div>
                            </Stack>

                            <Card>
                                <Scrollbar>
                                    <Box sx={{minWidth: 800}}>
                                        <Table sx={{
                                            '& .MuiTableCell-root': {
                                                whiteSpace: 'nowrap'
                                            }
                                        }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        ID
                                                    </TableCell>
                                                    <TableCell>
                                                        Désignation
                                                    </TableCell>
                                                    <TableCell>
                                                        Tooltip
                                                    </TableCell>

                                                    <TableCell>
                                                        Pour transporteur
                                                    </TableCell>
                                                    <TableCell>
                                                        Pour expéditeur
                                                    </TableCell>

                                                    <TableCell>
                                                        Obligatoire ?
                                                    </TableCell>
                                                    <TableCell>
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {document_types.map((type) => (
                                                        <DocumentTypeItem type={type} key={type.id}
                                                                          deleteType={this.deleteType}
                                                                          updateType={this.updateType}

                                                                          in_progress={
                                                                              this.state.progress_items.findIndex(
                                                                                  (item) => item.id === type.id
                                                                              ) !== -1
                                                                                  ? true
                                                                                  : false
                                                                          }

                                                                          selected={
                                                                              this.state.selected_items.findIndex(
                                                                                  (item) => item.id === type.id
                                                                              ) !== -1
                                                                                  ? true
                                                                                  : false
                                                                          }

                                                                          toggleStatus={this.toggleStatus}
                                                        />
                                                    )
                                                )}


                                            </TableBody>
                                        </Table>

                                        <Box sx={{display: 'flex', justifyContent: 'center', padding: 4}}>
                                            {
                                                loading &&
                                                <CircularProgress/>
                                            }

                                        </Box>

                                    </Box>
                                </Scrollbar>
                                {
                                    !loading &&
                                    <Box sx={{display: 'flex', justifyContent: 'center', padding: 4, pt: 0}}>
                                        <Pagination count={total_pages} page={page} onChange={(e, value) => {
                                            this.changePage(value);
                                        }}/>
                                    </Box>
                                }

                            </Card>


                        </Stack>
                    </Container>
                </Box>

                {
                    this.state.deleteItem &&
                    <DeleteDialog
                        item={this.state.deleteItem}
                        handleClose={this.cancelDeleteType}
                        confirmDelete={this.confirmDeleteType}
                    />
                }

                {
                    this.state.showForm &&
                    <FormDialog item={this.state.updateItem} handleClose={this.closeForm} refresh={this.refresh}/>
                }
            </>
        )
    }
}

export default Index;
