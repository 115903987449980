import React, { Component } from "react";
import ArrowDownOnSquareIcon from '@heroicons/react/24/solid/ArrowDownOnSquareIcon';
import ArrowUpOnSquareIcon from '@heroicons/react/24/solid/ArrowUpOnSquareIcon';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';

import UserService from "../../services/UserService";
import { Scrollbar } from "../../components/scrollbar";
import UserItem from "./UserItem";
import FormDialog from './FormDialog';
import DeleteDialog from './DeleteDialog';
import MagnifyingGlassIcon from '@heroicons/react/24/solid/MagnifyingGlassIcon';
import { InputAdornment, OutlinedInput } from '@mui/material';
import {
    Button, Container, SvgIcon,
    Avatar,
    Box,
    Card,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import UpdateExpireAt from "./UpdateExpireAt";

class Index extends Component {
    constructor(props) {
        super(props);
        this.isUserAdmin = props.isUserAdmin;
        this.retreiveUsers = this.retreiveUsers.bind(this);
        this.changePage = this.changePage.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.refresh = this.refresh.bind(this);
        this.toggleStatus = this.toggleStatus.bind(this);

        this.updateItem = this.updateItem.bind(this);
        this.closeUpdateItem = this.closeUpdateItem.bind(this);

        this.deleteItem = this.deleteItem.bind(this);
        this.closeDeleteItem = this.closeDeleteItem.bind(this);
        this.confirmDeleteUser = this.confirmDeleteUser.bind(this);
        this.search = this.search.bind(this);
        this.filterRole = this.filterRole.bind(this);


        this.state = {
            users: [],
            loading: false,
            total: 0,
            page: 1,
            limit: 10,
            total_pages: 0,
            progress_items: [],
            selected_items: [],

            updateItem: null,
            deleteItem: null,


            filter: {
                keyword: "",
                type: ""
            }
        }
    }

    componentDidMount() {
        this.retreiveUsers();
    }

    async retreiveUsers() {
        this.setState({
            loading: true
        })
        var res = await UserService.getAll(this.state.page, this.state.limit, this.state.filter);

        this.setState({
            loading: false,
            users: res.data.data,
            total_pages: res.data.total_pages,
            total: res.data.total
        })

    }

    changePage(page) {
        this.setState({
            page: page,
            users: []
        }, () => {
            this.retreiveUsers();
        })

    }

    async deleteUser(user) {
        this.setState({
            deleteItem: user
        })
    }

    async confirmDeleteUser(user) {
        this.setState({
            progress_items: [...this.state.progress_items, user]
        })
        var res = await UserService.delete(user.id);
        this.refresh();
    }

    async toggleStatus(user, attribue, object_attribute = null) {
        if (object_attribute == null) {
            object_attribute = attribue;
        }
        this.setState({
            progress_items: [...this.state.progress_items, user]
        })
        var res = await UserService.toggleStatus(user.id, attribue);
        this.setState({
            progress_items: this.state.progress_items.filter(item => item.id != user.id),
            users: this.state.users.map((c) => {
                if (c.id === user.id) {
                    return {
                        ...c,
                        [object_attribute]: !c[object_attribute]
                    };
                } else {
                    return c;
                }
            })
        })
    }

    refresh() {
        this.setState({
            page: 1,
            users: [],
            progress_items: [],
            selected_items: [],
            deleteItem: null,
            updateItem: null

        })
        this.retreiveUsers();
    }

    updateItem(item) {
        this.setState({
            updateItem: item
        });
    }

    closeUpdateItem() {
        this.setState({
            updateItem: null
        });
    }

    deleteItem(item) {
        this.setState({
            deleteItem: item
        });
    }

    closeDeleteItem() {
        this.setState({
            deleteItem: null
        });
    }

    search(e) {
        console.log("CHANGE")
        this.setState({
            page: 1,
            filter: {
                ...this.state.filter,
                keyword: e.target.value
            }
        }, () => {
            this.refresh();
        })
    }

    filterRole(e) {
        this.setState({
            page: 1,
            filter: {
                ...this.state.filter,
                type: e.target.value
            }
        }, () => {
            this.refresh();
        })
    }

    render() {

        const { users, loading, total, limit, page, total_pages, filter } = this.state;
        return (
            <>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        py: 5
                    }}
                >
                    <Container maxWidth="xl">
                        <Stack spacing={3}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={4}
                            >
                                <Stack spacing={1}>
                                    <Typography variant="h4">
                                        Utilisateurs
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Box sx={{ dispalay: 'flex', justifyContent: "space-between" }}>

                                <Card sx={{ p: 2, display: 'flex', justifyContent: "space-between" }}>
                                    <OutlinedInput
                                        defaultValue=""
                                        fullWidth
                                        placeholder="Recherche..."
                                        startAdornment={(
                                            <InputAdornment position="start">
                                                <SvgIcon
                                                    color="action"
                                                    fontSize="small"
                                                >
                                                    <MagnifyingGlassIcon />
                                                </SvgIcon>
                                            </InputAdornment>
                                        )}
                                        sx={{ maxWidth: 500 }}
                                        value={filter.keyword}
                                        onChange={this.search}
                                    />

                                    <FormControl sx={{ width: "200px" }}>
                                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.filter.type}
                                            label="Age"
                                            onChange={this.filterRole}
                                        >
                                            <MenuItem value={''}>Tous</MenuItem>
                                            <MenuItem value={'TRANSPORTEUR'}>Transporteur</MenuItem>
                                            <MenuItem value={"COMMISSIONNAIRE"}>Commissionnaire</MenuItem>
                                            <MenuItem value={"CHAUFFEUR"}>Chauffeur</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Card>


                            </Box>


                            <Card>
                                <Scrollbar>
                                    <Box sx={{ minWidth: 800 }}>
                                        <Table sx={{
                                            '& .MuiTableCell-root': {
                                                whiteSpace: 'nowrap'
                                            }
                                        }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        ID
                                                    </TableCell>
                                                    <TableCell>
                                                        inscrit le
                                                    </TableCell>
                                                    <TableCell>
                                                        Nom
                                                    </TableCell>
                                                    <TableCell>
                                                        Prénom
                                                    </TableCell>
                                                    <TableCell>
                                                        Email
                                                    </TableCell>
                                                    <TableCell>
                                                        Téléphone
                                                    </TableCell>
                                                    <TableCell>
                                                        Type
                                                    </TableCell>
                                                    <TableCell>
                                                        Société
                                                    </TableCell>
                                                    <TableCell>
                                                        Verifie ?
                                                    </TableCell>
                                                    {
                                                        this.isUserAdmin &&
                                                        <>
                                                            <TableCell>
                                                                Documents validés ?
                                                            </TableCell>
                                                            <TableCell>
                                                                Accès bourse privée
                                                            </TableCell>
                                                            <TableCell>
                                                            </TableCell>
                                                        </>
                                                    }

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {users.map((user) => (
                                                    <UserItem user={user} key={user.id}
                                                        deleteUser={this.deleteUser}
                                                        updateUser={this.updateItem}
                                                        isUserAdmin={this.isUserAdmin}

                                                        in_progress={
                                                            this.state.progress_items.findIndex(
                                                                (item) => item.id === user.id
                                                            ) !== -1
                                                                ? true
                                                                : false
                                                        }

                                                        selected={
                                                            this.state.selected_items.findIndex(
                                                                (item) => item.id === user.id
                                                            ) !== -1
                                                                ? true
                                                                : false
                                                        }
                                                        toggleStatus={this.toggleStatus}
                                                    />
                                                )
                                                )}


                                            </TableBody>
                                        </Table>

                                        {
                                            loading &&
                                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 4 }}>
                                                <CircularProgress />

                                            </Box>
                                        }

                                    </Box>
                                </Scrollbar>

                                {
                                    !loading &&
                                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 4 }}>
                                        <Pagination count={total_pages} page={page} onChange={(e, value) => {
                                            this.changePage(value);
                                        }} />
                                    </Box>
                                }

                            </Card>


                        </Stack>
                    </Container>
                </Box>
                {
                    this.state.updateItem && <FormDialog item={this.state.updateItem}
                        handleClose={this.closeUpdateItem}
                        refresh={this.refresh} />
                }

                {
                    this.state.deleteItem && <DeleteDialog item={this.state.deleteItem}
                        handleClose={this.closeDeleteItem}
                        confirmDelete={this.confirmDeleteUser}
                    />
                }



            </>
        )
    }
}

export default Index;
