import React, { Component } from "react";

import Login from "./views/auth/Login";
import Home from "./views/home";
import { Layout as DashboardLayout } from "./layouts/dashboard/Layout";

import Admins from "./views/admins/index";
import Countries from "./views/country/index";
import Users from "./views/users/index";
import Folder from "./views/users/Folder";
import DocumentTypes from "./views/document_type/index";
import Proposals from "./views/proposals/index";
import WebErrors from "./views/webErrors/index";

import CreateUser from "./views/admins/create";
import UpdateUser from "./views/admins/update";
import NotFound from "./views/NotFound";
import Loading from "./views/Loading";
import OffreDeService from "./views/OffreDeService";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "./theme";
import { connect } from "react-redux";
import { me, logout } from "./store/actions/auth";

import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import { CoPresent } from "@mui/icons-material";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    // check if is logged
    this.props
      .me()
      .then((res) => {
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const theme = createTheme();
    const logged = this.props.session.logged;
    const { loading } = this.state;
    const user = this.props.session.user;
    const isUserAdmin = user && user.roles.some((role) => ["ADMIN", "SUPER_ADMIN"].includes(role));
    const isUserSuperAdmin = user && user.roles.includes("SUPER_ADMIN");
    console.log({ user });
    console.log({ isUserAdmin });
    console.log({ isUserSuperAdmin });
    return (
      <Router>
        <ThemeProvider theme={theme}>
          {loading && (
            <>
              <Loading />
            </>
          )}
          {!loading && (
            <>
              {logged && (
                <DashboardLayout user={user}>
                  <Switch>
                    <Route exact path={["/", "/home"]} component={withRouter(Home)} />
                    {/* <Route exact path="/users" component={withRouter(Users)} /> */}
                    <Route exact path="/offreservice" component={withRouter(OffreDeService)} />
                    {isUserAdmin && (
                      <>
                        <Route exact path="/users" render={(props) => <Users {...props} isUserAdmin={isUserAdmin} />} />
                        <Route exact path="/users/folder/:id" component={withRouter(Folder)} />
                        <Route exact path="/proposals" component={withRouter(Proposals)} />
                        {isUserSuperAdmin && (
                          <>
                            <Route exact path="/create/admin" component={withRouter(CreateUser)} />
                            <Route path="/admins/update/:id" component={withRouter(UpdateUser)} />
                            <Route exact path={["/admins"]} component={withRouter(Admins)} />
                            <Route exact path={["/document_types"]} component={withRouter(DocumentTypes)} />
                            <Route exact path="/countries" component={withRouter(Countries)} />
                            <Route exact path="/webErrors" component={withRouter(WebErrors)} />
                          </>
                        )}
                      </>
                    )}
                    <Route path="/*" component={withRouter(NotFound)} />
                  </Switch>
                </DashboardLayout>
              )}
              {!logged && (
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/404" component={NotFound} />
                  <Route exact path="*" component={Login} />
                </Switch>
              )}
            </>
          )}
        </ThemeProvider>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};
export default connect(mapStateToProps, { me, logout })(App);
