import http from "../ http-common";
import axios from "axios";
import { BASE_URL as API_URL } from "../config";

const BASE_URL = API_URL + "/admin";
class AdminService {
  getAll(page, limit) {
    return http.get(BASE_URL + "/admins", {
      params: {
        page,
        limit,
      },
    });
  }

  get(id) {
    return http.get(`/admin/admins/${id}`);
  }

  create(data) {
    var formData = new FormData();
    formData.append("password", data.password);
    return axios.post(BASE_URL + "/admins", data);

    //return http.post("/admin/admins", data);
  }

  update(id, form) {
    // send action
    var formData = new FormData();
    for (const [key, value] of Object.entries(form)) {
      formData.append(`${key}`, `${value}`);
    }

    let config = {
      headers: {
        withCredentials: false,
        Authorization: "Bearer " + window.localStorage.token,
        // 'Access-Control-Allow-Origin': '*',
        // 'Accept': "*/*",
        // 'X-Requested-With': "XMLHttpRequest",
        // // 'Access-Control-Allow-Headers': '*',
        // 'Content-Type': 'application/json'
      },
    };

    return axios.post(`/admin/update/admin`, form, config);
  }

  delete(id) {
    return http.delete(`/admin/admins/${id}`);
  }

  deleteAll() {
    return http.delete(`/admin/admins`);
  }
}

export default new AdminService();
