import React, { Component } from "react";
import { Link } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {
    TableCell,
    TableRow,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

class DocumentTypeItem extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { type, deleteType, updateType, in_progress, selected, toggleSelectCountry, toggleStatus } = this.props;
        // const isSelected = selected.includes(customer.id);
        // const createdAt = format(customer.createdAt, 'dd/MM/yyyy');
        return (
            <>

                <TableRow
                    hover
                    selected={false}
                >
                    <TableCell>
                        {type.id}
                    </TableCell>

                    <TableCell>
                        {type.name}
                    </TableCell>

                    <TableCell>
                        {type.tooltip}
                    </TableCell>

                    <TableCell>
                        {
                            !in_progress && <>

                                <FormGroup>
                                    <FormControlLabel control={<Switch checked={type.forTransporteur}
                                        onChange={() => {
                                            toggleStatus(type, "forTransporteur")
                                        }}
                                    />} label="" />
                                </FormGroup>
                            </>
                        }
                        {
                            in_progress && <CircularProgress />
                        }
                    </TableCell>

                    <TableCell>
                        {
                            !in_progress && <>

                                <FormGroup>
                                    <FormControlLabel control={<Switch checked={type.forExpediteur}
                                        onChange={() => {
                                            toggleStatus(type, "forExpediteur")
                                        }}
                                    />} label="" />
                                </FormGroup>
                            </>
                        }
                        {
                            in_progress && <CircularProgress />
                        }
                    </TableCell>

                    <TableCell>
                        {
                            !in_progress && <>

                                <FormGroup>
                                    <FormControlLabel control={<Switch checked={type.isRequired}
                                        onChange={() => {
                                            toggleStatus(type, "isRequired")
                                        }}
                                    />} label="" />
                                </FormGroup>
                            </>
                        }
                        {
                            in_progress && <CircularProgress />
                        }
                    </TableCell>

                    <TableCell>
                        {
                            !in_progress &&
                            <>
                                <BootstrapTooltip title="Supprimer le type" placement="top"
                                    color={"primary"}>
                                    <IconButton aria-label="delete" size="large" color={"error"}
                                        onClick={() => {
                                            deleteType(type)
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </BootstrapTooltip>

                                <BootstrapTooltip title="Modifier le type" placement="top"
                                    color={"primary"}>
                                    <IconButton color="primary" aria-label="Modifier"
                                        component={Link}
                                        onClick={() => {
                                            updateType(type)
                                        }}
                                    >
                                        <ModeEditIcon />
                                    </IconButton>
                                </BootstrapTooltip>

                            </>
                        }
                    </TableCell>
                </TableRow>
            </>
        )
    }
}

export default DocumentTypeItem;
