import React, { Component } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import ProposalsService from "../../services/ProposalsService";
import { Scrollbar } from "../../components/scrollbar";
import ProposalItem from "./ProposalItem";
import MagnifyingGlassIcon from '@heroicons/react/24/solid/MagnifyingGlassIcon';
import { InputAdornment, OutlinedInput } from '@mui/material';
import {
    Button, Container, SvgIcon,
    Avatar,
    Box,
    Card,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

class Index extends Component {
    constructor(props) {
        super(props);

        this.retreiveProposals = this.retreiveProposals.bind(this);
        this.changePage = this.changePage.bind(this);
        this.refresh = this.refresh.bind(this);

        this.search = this.search.bind(this);
        this.filterRole = this.filterRole.bind(this);

        this.state = {
            proposals: [],
            loading: false,
            total: 0,
            page: 1,
            limit: 10,
            total_pages: 0,
            progress_items: [],
            selected_items: [],

            updateItem: null,
            deleteItem: null,

            filter: {
                keyword: "",
                type: ""
            }
        }
    }

    componentDidMount() {
        this.retreiveProposals();
    }

    async retreiveProposals() {
        this.setState({
            loading: true
        })
        var res = await ProposalsService.getAll(this.state.page, this.state.limit);

        this.setState({
            loading: false,
            proposals: res.data.proposals,
            total_pages: res.data.total_pages,
            total: res.data.total
        })

    }

    changePage(page) {
        this.setState({
            page: page,
            proposals: []
        }, () => {
            this.retreiveProposals();
        })

    }

    refresh() {
        this.setState({
            page: 1,
            proposals: [],
            progress_items: [],
            selected_items: [],
            deleteItem: null,
            updateItem: null
        })
        this.retreiveProposals();
    }

    updateItem(item) {
        this.setState({
            updateItem: item
        });
    }

    search(e) {
        console.log("CHANGE")
        this.setState({
            page: 1,
            filter: {
                ...this.state.filter,
                keyword: e.target.value
            }
        }, () => {
            this.refresh();
        })
    }

    filterRole(e) {
        this.setState({
            page: 1,
            filter: {
                ...this.state.filter,
                type: e.target.value
            }
        }, () => {
            this.refresh();
        })
    }

    render() {

        const { proposals, loading, total, limit, page, total_pages, filter } = this.state;
        console.log("proposals : ", proposals)
        return (
            <>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        py: 5
                    }}
                >
                    <Container maxWidth="xl">
                        <Stack spacing={3}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={4}
                            >
                                <Stack spacing={1}>
                                    <Typography variant="h4">
                                        Propositions
                                    </Typography>
                                </Stack>
                            </Stack>


                            <Card>
                                <Scrollbar>
                                    <Box sx={{ minWidth: 800 }}>
                                        <Table sx={{
                                            '& .MuiTableCell-root': {
                                                whiteSpace: 'nowrap'
                                            }
                                        }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        N°
                                                    </TableCell>
                                                    <TableCell>
                                                        EXpéditeur
                                                    </TableCell>
                                                    <TableCell>
                                                        Transporteur
                                                    </TableCell>
                                                    <TableCell>
                                                        Prix proposé
                                                    </TableCell>
                                                    <TableCell>
                                                        Dates proposée
                                                    </TableCell>
                                                    <TableCell>
                                                        Chauffeur
                                                    </TableCell>
                                                    <TableCell>
                                                        Status
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {proposals.map((proposal) => (
                                                    <ProposalItem proposal={proposal} key={proposal.id}

                                                        in_progress={
                                                            this.state.progress_items.findIndex(
                                                                (item) => item.id === proposal.id
                                                            ) !== -1
                                                                ? true
                                                                : false
                                                        }

                                                        selected={
                                                            this.state.selected_items.findIndex(
                                                                (item) => item.id === proposal.id
                                                            ) !== -1
                                                                ? true
                                                                : false
                                                        }

                                                        toggleStatus={this.toggleStatus}
                                                    />
                                                )
                                                )}


                                            </TableBody>
                                        </Table>

                                        {
                                            loading &&
                                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 4 }}>
                                                <CircularProgress />
                                            </Box>
                                        }

                                    </Box>
                                </Scrollbar>

                                {
                                    !loading &&
                                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 4 }}>
                                        <Pagination count={total_pages} page={page} onChange={(e, value) => {
                                            this.changePage(value);
                                        }} />
                                    </Box>
                                }

                            </Card>


                        </Stack>
                    </Container>
                </Box>

                {
                    this.state.deleteItem && <DeleteDialog item={this.state.deleteItem}
                        handleClose={this.closeDeleteItem}
                        confirmDelete={this.confirmDeleteProposal}
                    />
                }



            </>
        )
    }
}

export default Index;
