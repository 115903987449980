import { Component } from 'react';

import { Box, Divider, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import {connect} from "react-redux";
import {logout, me} from "../../store/actions/auth";
class AccountPopover extends Component {
    constructor(props) {
        super(props);

        this.logOut = this.logOut.bind(this);
    }

    logOut(){
        this.props.logout();
    }

    render() {

        const {anchorEl, onClose, open, session} = this.props;

        return (
            <>
                <Popover
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom'
                    }}
                    onClose={onClose}
                    open={open}
                    PaperProps={{ sx: { width: 200 } }}
                >
                    <Box
                        sx={{
                            py: 1.5,
                            px: 2
                        }}
                    >
                        <Typography variant="overline">
                            Account
                        </Typography>
                        <Typography
                            color="text.secondary"
                            variant="body2"
                        >
                            {session.user.firstname} {session.user.lastname}
                        </Typography>
                    </Box>
                    <Divider />
                    <MenuList
                        disablePadding
                        dense
                        sx={{
                            p: '8px',
                            '& > *': {
                                borderRadius: 1
                            }
                        }}
                    >
                        <MenuItem onClick={() => this.logOut()}>
                            Se deconnecter
                        </MenuItem>
                    </MenuList>
                </Popover>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        session: state.session
    };
};
export default connect(mapStateToProps, {logout})(AccountPopover);